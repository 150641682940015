@use "../../../App/Base.module.scss";

.backdrop{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 100px 0 0 90px;
    cursor:default;
}

.settingsModal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
    width: 400px;
    background-color: white;
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25));

    > h4 {
        @include Base.h4em-med;
    }

    > div {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }
}