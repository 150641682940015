
@use '../../App/Base.module.scss';

.filterButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    min-width: 105px;
    max-width: 105px;
    height: 36px;
    border-radius: 4px;
    @include Base.copy-med;
    cursor: pointer;   
}

.mobile {
    color: Base.$light;
    border: 2px solid Base.$light;
    background-color: rgba(255, 255, 255, 0);

    > img {
        filter: Base.$white-svg;
    }
}

.desktop {
    color: Base.$highlight-1;
    border: 2px solid Base.$highlight-1;

    > img {
        filter: Base.$hl-1svg;
    }
}

.desktop:hover {
    background-color: Base.$hl-1hover;
}


