
@use '../../../App/Base.module.scss';

.phrase {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    //min-height: 20%;
    //overflow-y: scroll;
}

.phraseMobile {
    padding-left: 8%;
}

.notReadYet {
    filter: opacity(25%);
}

.mobilePlayBtn {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    left: 4px;
    z-index: 1;
    cursor: pointer;
    filter: invert(56%) sepia(59%) saturate(635%) hue-rotate(348deg) brightness(97%) contrast(88%);
}

.mobilePlayBtn:active {
    transform: scale(.989);
    transition: transform 25ms;
}

.bothTypes {
    top: 22px;
}

.pinyin {
    top: 8px;
}

.hanzi {
    top: 6px;
}

@media screen and (max-width: Base.$mobile-screen) {


}