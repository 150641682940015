@use '../../App/Base.module.scss';

.searchInput::-webkit-search-decoration,
.searchInput::-webkit-search-cancel-button,
.searchInput::-webkit-search-results-button,
.searchInput::-webkit-search-results-decoration {
  display: none;
}

.searchBar {
    display: flex;
    position: relative;

    > img {
        position: absolute;
        top: 4px;
        left: 16px;
    }
}

.searchInput {
    @include Base.copy-med;
    max-width: 540px;
    height: 32px;
    border: none;
    border-radius: 20px;
    padding: 0 1rem 0 3rem;
}

.searchInput:focus {
    outline-width: 0;
}

@media screen and (max-width: Base.$mobile-screen) {

    .searchInput {
        width: 100%
    }
}