@use '../../../App/Base.module.scss';

.backdrop {
    @include Base.modalBackdrop;
}

.previewModal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 90%;
    max-width: 792px;
    max-height: 768px;
    border-radius: 8px;
    background-color: Base.$light;
    box-shadow: Base.$boxShadow;
    padding: 2rem;
    overflow: auto;


}

.phraseHeader {
    @include Base.h2em;
    padding-bottom: 1.5rem;
}

.phraseLine {
    display: flex;
    padding-bottom: 1rem;

    > div {
        display: flex;
        flex-direction: column;

        > p, label {
            @include Base.copy-med;
            padding-bottom: 0.5rem;
        }
    }
}

.phrasePreview {
    width: 40%;
    padding-right: 1rem;

    > div {
        display: flex;
        align-items: center;
        background-color: Base.$bg-light;
        height: 100%;
        padding: 0.5rem;
        border-radius: 4px;

        > img {
            width: 18px;
            margin-right: 0.5rem;
            cursor: pointer;
        }

        > p {
            @include Base.copy-med;
        }
    }
}

.translationPreview {
    width: 60%;

    > div {
        height: 100%;
        padding: 0.5rem;
        border-radius: 4px;
        border: 1.5px solid Base.$light-200;

        > p {
            @include Base.copy-med;
        }
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
}

.message {
    @include Base.copy-bold;
}

.wordlist {
    padding: 1rem 0;
    overflow: auto;

    > p {
        @include Base.copy-med;
        font-size: 18px;
        padding: 0.25rem 0 ;
    }
}