
@use '../../Pages/PageGrid.module.scss';
@use "../../App/Base.module.scss";

/* .page {
    height: 100%;
} */

.results {
    grid-column: 1 / 5;
    grid-row: 1/2;
    height: inherit;
    font-family: Base.$font-primary;

    padding-bottom: 5%;
    overflow: scroll;
}

.resultsTop {
    margin: 2rem 0;
    border-bottom: 2px solid #D9D9D9;
}

.resultsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.resultsMessage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    > h1 {
        @include Base.h3em;
    }

    > h2 {
        @include Base.copy-med;

    }
}

.resultsData {
    display: flex;
    justify-content: center;
    padding-top: 2rem;

}

.resultScore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;

    > div {
        display: flex;
        flex-direction: row;
        color: Base.$dark-700;

        > h4 {
            @include Base.copy-med;
            padding: 0 .25rem;
            max-width: 90px;
            text-align: center;
            align-self: center;
        }
        
        > h3 {
            @include Base.h3em;
            padding: 0 .25rem;
        }

        .score {
            color: Base.$highlight-6;
        }
    }

    .scoreContainer {
        padding: 1rem;
    }
}

.flashcardNav {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 2rem 0;
    
    > a, button {
        margin: 0 1rem;
    }
}

.resultsBottom {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media screen and (max-width: Base.$mobile-screen) {

    .results {
        grid-row: 2 / 4;
        height: 100%;
    }

    .resultsTop {
        margin: 0 0 2rem;
    }

    .testResults {
        flex-direction: column;

        > h1 {
            font-size: 24px;
        }
    }
}

@media screen and (min-width: Base.$mobile-screen) {

    .resultsMessage {

        > h2 {
            @include Base.h3em;
            padding-left: 0.5rem;
        }
    }

    .results {
        grid-column: 1 / 13;
    }
}

@media screen and (min-width: Base.$l-screen) {

    .resultsMessage {

        > h1 {
            @include Base.h2em;
        }

        > h2 {
            @include Base.h2em;
            padding-left: 0.5rem;
        }
    }

    .results {
        grid-column: 2 / 12;
        padding: 0 0 5%;
    }
}

@media screen and (min-width: Base.$xl-screen) {

    .results {
        grid-column: 2 / 12;
        padding: 0 5% 5%;
    }
}

@media screen and (min-width: Base.$xxl-screen) {

    .results {
        grid-column: 3 / 11;
    }
}