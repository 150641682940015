@use '../../../App/Base.module.scss';

.selector {
    position: relative;
    display: flex;
    padding: 0 1.25rem;
    align-items: center;
    width: 200px;
    height: 56px;
    border: 2px solid #13C2C2;
    border-radius: 4px;
    cursor: pointer;

    > div {

        > p {
            @include Base.copy-bold;
            padding-right: 1rem;
        }
    }
}

.list {
    position: absolute;
    top: 60px;
    left: -1px;
    width: 200px;
    max-height: 200px;
    background-color: Base.$light;
    border: 2px solid Base.$highlight-6;
    border-radius: 4px;
    padding: .25rem 0;
    z-index: 1;
    overflow: auto;

    > p {
        @include Base.copy-med;
        padding: 1rem 0;
        margin: 0 1rem;
        cursor: pointer;
    }
}

.selectorPref {
    position: relative;
    max-height: 3rem;
    width: 48%;
    padding: .5rem 1rem;
    background-color: Base.$light;
    border-radius: 4px;
    cursor: pointer;

    > div {
        display: flex;
        justify-content: space-between;

        > p {
            @include Base.copy-bold;
        }
    }   
}

.listPref {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    max-height: 200px;
    background-color: Base.$light;
    border: 2px solid Base.$highlight-6;
    border-radius: 4px;
    overflow: auto;
    z-index: 1;

    > p {
        @include Base.copy-med;
        padding: 1rem ;
        cursor: pointer;
    }

    > p:hover {
        background: Base.$body-background;
    }
}

.outlineSelected {
    border: 2px solid Base.$highlight-6;
}

.outline {
    border: 2px solid Base.$light;
}

.listBorder {
    border-bottom: 2px solid Base.$light-200;
}

.noborder {
    border: none;
}

.dropdownArrow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}