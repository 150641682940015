@use '../../../App/Base.module.scss';

.createForm {
    display: flex;
    flex-direction: column; 
    height: 100%;
    width: 100%;
    min-width: 600px;
}

.lessonTitle {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

    > h4 {
        @include Base.h4-bold;
    }

    > p {
        @include Base.copy-med;
        color: Base.$dark-700;
    }
}


.lessonMetaEditor {
    width: inherit;
    height: max-content;
}



.inputCreate {

    > textarea {
        height: 38px;
        width: 100%
    } 
}

.inputBox {
    @include Base.copy-med;
    border: 1.5px solid Base.$light-200;
    border-radius: 4px;
    padding: 8px;
    resize: none;
}

.inputBox::placeholder {
    color: Base.$light-500;
}

.titleEditor {
    width: 100%;
    max-width: 276px;
    padding: 0;

    > textarea {
        height: 80px;
    }
}

.descriptionEditor {
    width: 100%;
    max-width: 376px;

    > textarea {
        height: 80px;
    }
}

.tagEditor {
    height: 80px;
}

.createTags {
    display: flex;
    flex-direction: column;

    width: 376px;
    
    > textarea {
        margin-bottom: 1rem;
    }
    
    > label {
        @include Base.copy-med;
        padding-bottom: 0.5rem;
        max-width: 260px;
    }
}


.bottomForm {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
}