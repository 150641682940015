
@use '../App/Base.module.scss';

// This page contains the basic grid style and media queries for each page

.pageGrid {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 78px 1fr 90px;
    height: 100vh;
    gap: 0 0.5rem;
    padding: 0 1rem;
    overflow: scroll;
}

.fcGridMobile {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 50px 1fr;
    //height: 100vh;
    gap: 0 0.5rem;
    padding: 0 1rem;
    //overflow-y: auto;
}




@media screen and (min-width: Base.$mobile-screen) {
    
    .pageGrid {
        grid-auto-flow: column;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: none;
        height: 100%;
        gap: 0 1.5rem;
        padding: 0 1.5rem;
    }
}

@media screen and (min-width: Base.$xl-screen) {

    .pageGrid {
        grid-template-columns: repeat(12, 1fr);;
    }
}

@media screen and (min-width: Base.$xxl-screen) {

    .pageGrid {
        grid-template-columns: repeat(12, 1fr);
        padding: 0 calc((100% - 1440px) / 2);
    }
}