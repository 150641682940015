@use '../../App/Base.module.scss';

.breadCrumbs {
    display: flex;

    > h4 {
        font-family: Base.$font-primary;
        @include Base.h4em-med
    }

    > img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        filter: invert(74%) sepia(0%) saturate(13%) hue-rotate(239deg) brightness(95%) contrast(100%);
    }
}

@media screen and (max-width: Base.$m-screen) {
    
    .breadCrumbs {
        display: none;
    }
}




