@use '../../App/Base.module.scss';

.filterSidebar {
    grid-row: 1 / 4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 276px;
    height: 100%;
    background-color: Base.$light;

    padding: 3.5rem 1rem 1rem 1.5rem;
    z-index: 1;
}

.filterHeader {
    @include Base.h4-bold;
    padding-bottom: 1rem;
}

.filterList {
    padding-bottom: 4rem;
}

.weekList {
    padding-left: 1rem;
}

.checkBoxLine {
    display: flex;
    padding-bottom: 1rem;

    > p {
        @include Base.copy-med;
        padding-top: 0.25rem;
        padding-left: 0.5rem;
    }

    .selected {
        color: Base.$highlight-1;
    }

}