@use '../../App/Base.module.scss';

.lessonNav {
    position: absolute;
    right: 0;
    top: 110px;
    display: flex;
    justify-content: flex-end;
    align-self: flex-start;
    justify-content: center;
    width: max-content;

    > nav {
        margin-right: 30px;
    }

    > img {
        width: 40px;
        height: 40px;
        margin-top: 18px;
        filter: invert(56%) sepia(81%) saturate(469%) hue-rotate(131deg) brightness(96%) contrast(89%);
        cursor: pointer;
    }
}

.pageNavModal {
    min-height: 244px;
    background-color: Base.$light;
    box-shadow: Base.$boxShadow;
    border-radius: 8px;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
}

.closeToggle {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);  
}

.lessonLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 62px;
    border: none;
    border-radius: 4px;
    margin-bottom: 24px;
    color: white;
    font-family: Base.$font-primary;
    background-color: Base.$highlight-6;
    @include Base.h4em
}

.lessonLink:hover {
    background-color: Base.$highlight-5;
    cursor: pointer;
}

.lessonLinkClear {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 62px;
    margin-bottom: 24px;
    border-radius: 4px;
    border: 2px solid #13c3c3;
    font-family: Base.$font-primary;
    @include Base.h4em
}

.lessonLinkClear:hover {
    background-color: rgba(19, 195, 195, 0.25);
    cursor: pointer;
}

@media screen and (max-width: Base.$mobile-screen) { 

    .lessonNav {
        display: none;

    }
}

