@use '../../../App/Base.module.scss';

.backdrop {
    @include Base.modalBackdrop;
    top: 0;
}

.filter {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 450px;
    height: 350px;
    padding: 2rem 2rem;
    border-radius: 8px;
    background-color: white;
}

.closeButton {
    position: absolute;
    padding: 8px;
    right: 8px;
    top: 8px;
    cursor: pointer;
}

.selection {
    text-align: center;

    > h3 {
        @include Base.h3em;
    }

    > p {
        @include Base.copy-med;
    }
}

.selector {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 56px;
    border: 2px solid Base.$highlight-6;
    border-radius: 4px;
    cursor: pointer;

    > p {
        @include Base.copy-bold;
        padding-right: 1rem;
    }
}

@media screen and (max-width: Base.$mobile-screen) {

    .backdrop {
        padding: 6rem 1rem 11rem;
    }
}