//@import 'https://fonts.googleapis.com/css2?family=Cabin&family=Inter&family=Oswald:wght@500&display=swap';
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;400&display=swap');
//Base App Headers
$font-primary: 'Raleway', sans-serif;
$font-primary-bold: 'Raleway', sans-serif;
$font-hanzi: 'Noto Serif SC', serif;
$font-pinyin: 'Noto Sans', sans-serif;

//BaseLayout Variables
$body-background: #F2F2F2;
$nav-background: #E98B2A;
$header-offset: 100px;
$grey: #858585;

//UI Component Colors
$button-orange: #FFB11B;
$color-yellow:#F0BC3B;

//Colors
$mobile-nav: #E7E7E7;
$bg-light: #F2F2F2;
$light: #FFFFFF;
$light-200: #D1D1D1;
$light-500: #B5B5B5;
$dark-700: #5B5B5B;
$dark: #000000;
$highlight-1: #E98B2A;
$highlight-2: #EE9B01;
$highlight-3: #DA6A00;
$highlight-5: #009090;
$highlight-6: #13C2C2;
$grammar-aid-color: #9E54FF;
$correct: #00A110;
$wrong: #FF0000;

//hover colors
$hl-1hover: rgba(233, 138, 42, 0.25);

//Svg colors
$white-svg: invert(100%) sepia(0%) saturate(0%) hue-rotate(8deg) brightness(101%) contrast(102%);
$hl-1svg: invert(73%) sepia(27%) saturate(6305%) hue-rotate(345deg) brightness(98%) contrast(86%);

$boxShadow: 0px 3px 4px rgba(0, 0, 0, 0.25);

//fonts
$h1: 3rem;
$h2: 2rem;
$h3: 1.5rem;
$h4: 1.25rem;
$h5: 1.25rem;
$copy: 1rem;

//Media query width
$mobile-screen: 576px;
$m-screen: 768px;
$l-screen: 992px;
$xl-screen: 1200px;
$xxl-screen: 1440px;

@mixin respond-to($breakpoint) {
    @if $breakpoint == small {
      @media (max-width: $mobile-screen) {
        @content;
      }
    } @else if $breakpoint == medium {
      @media (max-width: $m-screen) {
        @content;
      }
    } @else if $breakpoint == large {
      @media (max-width: $l-screen) {
        @content;
      }
    } @else if $breakpoint == extra-large {
      @media (max-width: $xl-screen) {
        @content;
      }
    } @else {
      @warn "Invalid breakpoint: #{$breakpoint}.";
    }
  }

//Media query height: 
$mobile-s: 770px;

//NavBar and header sizes
$navBarWidth: 70px;
$headerHeight: 52px;

//mobile navbar and header sizes
$navBarHeightMobile: 88px;

@mixin h1em { 
    font-family: $font-primary;
    font-size: 48px;
    line-height: 125%;
    font-weight: 800;
    
} 
@mixin h2em {
    font-family: $font-primary;
    font-size: 32px;
    line-height: 150%;
    font-weight: 800;
} 
@mixin h3em {
    font-family: $font-primary;
    font-size: 24px;
    line-height: 150%;
    font-weight: 700;
} 

//Anything that says h5/bold
@mixin h4em {
    font-family: $font-primary;
    font-size: 20px;
    line-height: 150%;
    font-weight: 700;
} 
@mixin h4em-med {
    font-family: $font-primary;
    font-size: 20px;
    line-height: 125%;
    font-weight: 500;
} 

@mixin h4em-reg {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
}

@mixin h4-bold {
    font-family: $font-primary;
    font-size: 20px;
    line-height: 120%;
    font-weight: 700;
}
@mixin copy-bold {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 125%;
    font-weight: 700;
}
@mixin copy-med {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 125%;
    font-weight: 500;
}
@mixin copy-reg {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 125%;
    font-weight: 400;
}
@mixin copy-sml {
    font-family: $font-primary;
    font-size: 14px;
    line-height: 125%;
    font-weight: 500;
}
@mixin h5-reg {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
}
@mixin kaiti-xs {
    font-family: $font-hanzi;
    font-size: 16px;
    line-height: 125%;
    font-weight: 400;
}
@mixin kaiti-sml {
    font-family: $font-hanzi;
    font-size: 24px;
    line-height: 125%;
    font-weight: 400;
}
@mixin kaiti-med {
    font-family: $font-hanzi;
    font-size: 32px;
    line-height: 150%;
    font-weight: 400;
    letter-spacing: 0.2em;
}
@mixin kaiti-big {
    font-family: $font-hanzi;
    font-size: 48px;
    line-height: 150%;
    font-weight: 200;
}
@mixin pinyin-sml {
    font-family: $font-pinyin;
    font-size: 12px;
    line-height: 125%;
    font-weight: 400;
}
@mixin pinyin-med {
    font-family: $font-pinyin;
    font-size: 16px;
    line-height: 125%;
    font-weight: 400;
}
@mixin pinyin-big {
    font-family: $font-pinyin;
    font-size: 24px;
    line-height: 125%;
    font-weight: 400;
}

@mixin nav-btn {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;    
    line-height: 125%;
}

@mixin nav-btn-large {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;    
    line-height: 125%;
}

//hover style
@mixin onHover {
    transform: scale(1.02);
    transition: transform 1ms;
}

@mixin modalBackdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: $navBarWidth;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.15);

    @media screen and (max-width: $l-screen) {
        padding-left: 0;
        background-color: rgba(0, 0, 0, 0);
    }
}
