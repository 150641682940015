@use '../../../App/Base.module.scss';

.flashcardsList {

    > h4 {
        @include Base.copy-bold;
        text-decoration: underline;
        padding: 1rem 0;
    }

    > p {
        @include Base.copy-med;
        color: Base.$light-500;
        padding: 0.5rem;
        cursor: pointer;
    }
}

.sectHeader {
    align-items: baseline;

    > h4 {
        @include Base.h4em;
    }

    > p {
        @include Base.copy-sml;
        color: Base.$light-500;
        cursor: pointer;
    }
}

.wordlist {
    display: flex;
    flex-wrap: wrap;
    width: 100%;


    .pinyinList{
        @include Base.copy-sml;
        line-height: 150%;
    }

    .hanziList {
        @include Base.kaiti-sml;
        font-size: 16px;
    }
}

.wordlistitem {
    padding: 0.25rem 0.5rem;
    margin: 0.25rem;
    background-color: white;
    border-radius: 4px;
}

@media screen and (max-width: Base.$mobile-screen) {
    
    .flashcardsList {
        width: auto;
    }
}