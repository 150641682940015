@use '../../PageGrid.module.scss';
@use '../../../App/Base.module.scss';

.adminPage {
    grid-column: 1 / 5;
    grid-row: 2 / 3;
    overflow-y: scroll;
    justify-self: center;
    width: 100%;
    max-width: 1080px;
}

.header {
    @include Base.h2em;
    padding: 2rem 0;
}

.selector {
    width: 100%;
    padding-bottom: 2rem;
    
    > a {
        padding: 8px 24px;
        margin-right: 1rem;
        border-radius: 8px;
        border: none;
        cursor: pointer;
    }
}

.unactiveOption {
    @include Base.copy-med;
    background-color: Base.$light;
}

.activeOption {
    @include Base.copy-bold;
    color: Base.$light;
    background-color: Base.$highlight-1;
}

@media screen and (min-width: Base.$mobile-screen) {
    
    .adminPage {
        grid-column: 1 / 13;
        grid-row: 1 / 3;
    }
}

@media screen and (min-width: Base.$l-screen) {

    .adminPage {
        justify-self: center;
    }
}

@media screen and (min-width: Base.$xl-screen) {

    .adminPage {
        grid-column: 2 / 12;
    }
}
















