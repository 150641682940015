@use '../../App/Base.module.scss';

.page {
    position: absolute;
    left: 40px;
    right: 0;
    top: 44px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}   

.spinner {
    border: 4px solid #13C2C2;
    border-top: 4px solid rgba(255, 255, 255, 0);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 1s linear infinite;
}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

@media screen and (max-width: Base.$mobile-screen) {

    .page {
        left: 0;
        top: 0;
    }
}