@use '../../App/Base.module.scss';

.noWordMessage {
    display: flex;
    justify-content: space-around;
    padding-top: 6rem;
    text-align: center;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 300px;
        height: 200px;
        background-color: Base.$light;
        border-radius: 8px;
        padding: 1rem;

        > h4 {
            @include Base.h4em;
            padding-bottom: 1rem;
        }
    
        > p {
            text-align: center;
        }
    }
    
}