@use '../../../App/Base.module.scss';

.backdrop {
    @include Base.modalBackdrop;
    z-index: 1;
}

.backdropModal {
    @include Base.modalBackdrop;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
}

.settingsModal {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 300px;
    background-color: Base.$light;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 1rem 0 ;
}

.settingsTop {
    padding-bottom: 1rem;

    > div {
        display: flex;
        justify-content: space-between;
        padding: 0 1.5rem;

        > p {
            @include Base.copy-med;
            color: Base.$dark-700;
        }
        
        > img {
            cursor: pointer;
        }
    }
}

.settingsBottom {
    display: flex;
    padding-top: 1rem;
    border-top: 1px solid Base.$light-200;
    padding: 1rem 0;
    margin: 0 1.5rem;

    .settingsButton {
        display: flex;
        flex-direction: column;
        margin-right: 1.5rem;
        cursor: pointer;
    }

    .buttonTop {
        display: flex;
        justify-content: center;
        align-items: center; 
        width: 50px;
        height: 28px;
        @include Base.copy-med;
        border: 1px solid Base.$light-200;
        border-radius: 4px;
    }

    .buttonBottom {
        @include Base.copy-sml;
        padding-top: 4px;
        text-align: center;
    }
}

