@use '../../../App/Base.module.scss';

.phraseMetaEditor {
    display: flex;
    flex-direction: column;
    width: inherit;
    height: 100%;
    padding-top: 1rem;
}

.phraseHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

    > h2 {
        @include Base.copy-bold;
    }
}

.phraseSelector {
    display: flex;

    > h2 {
        @include Base.copy-bold;
        padding: 4px 16px 0;
    }

    > button {
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    .chevronFlipped {
        transform: scaleX(-1)
    }
}

.phraseTranslation {
    display: flex;
    min-height: 84px;

    > div {
        display: flex;
        flex-direction: column;

        > h4, label {
            padding-bottom: 0.5rem;
        }

        > h4, p, label, textarea  {
            @include Base.copy-med;
        }
    }
}

.phrasePreview {
    width: 40%;
    padding-right: 1.5rem;

    > div {
        display: flex;
        align-items: center;
        padding-top: 0.5rem;

        > img {
            width: 18px;
            cursor: pointer;
        }
        
        > p {
            padding-left: 0.5rem;
        }
    }
}

.translationEditor {
    width: 60%;

    > textarea {
        font-size: 16px;
        line-height: 125%;
        font-weight: 500;
        border: 1.5px solid #D1D1D1;
        border-radius: 4px;
        padding: 8px;
        resize: none;
    }
}

.deleteButton {
    display: flex;
    align-items: center;
    @include Base.copy-med;
    color: Base.$dark-700;
    background-color: transparent;
    border: none;
    cursor: pointer;

    > img {
        padding: 0 8px 6px;
    }
}