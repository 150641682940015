@use '../../../App/Base.module.scss';

.phraseWordsEditor {
    padding-top: 3rem;
}

.timingContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        margin-bottom: 1rem;
    } 
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 40px;
    min-height: 94px;
    margin-right: 1rem;
    border: 1px dotted Base.$light-200;
    border-radius: 4px;

    > img {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}
