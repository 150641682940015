@use '../../../App/Base.module.scss';


.form {
    display: flex;
    flex-direction: column; 
    height: 100%;
    width: 100%;
    min-width: 600px;
}

.inputBox {
    @include Base.copy-med;
    //height: 80px;
    border: 1.5px solid Base.$light-200;
    border-radius: 4px;
    padding: 8px;
    resize: none;
   //margin-bottom: 1rem;
}

.inputBox::placeholder {
    color: Base.$light-500;
}

/* .lessonTitle {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

    > h4 {
        @include Base.h4-bold;
    }

    > p {
        @include Base.copy-med;
        color: Base.$dark-700;
    }
} */

/* Title, description, tag adjustment */
.lessonMetaEditor {
    display: flex;
    width: inherit;
    height: max-content;
    padding-bottom: 3rem;

    > div {
        display: flex;
        flex-direction: column;
        padding-right: 1.5rem;
        
        > textarea {
            height: 80px;
        }

        > input {
            height: 40px
        }

        > label {
            @include Base.copy-med;
            padding-bottom: 0.5rem;
        }
    }
}

.titleEditor {
    width: 100%;
    max-width: 276px;
}

.descriptionEditor {
    width: 100%;
    max-width: 376px;
}

.tagEditor {
    height: 80px;
}

.phraseEditorContainer {
    background-color: Base.$light;
    padding: 1rem;
    border-radius: 8px;
}

/* Phrase editor */


/* Word editor */





.submitbutton {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
}

.message {
    @include Base.copy-bold;
}

.wordlist {
    overflow: scroll;

    > p {
        @include Base.copy-med;
    }
}
