@use '../../../App/Base.module.scss';

.wordList {
    overflow-y: scroll;
    border-radius: 6px;
}

.wordListItem {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    background-color: white;
}

.wordListDual {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    background-color: white;

    > div {
        margin: 0;

        > img {
            margin: 0 0.5rem 0 1rem;
        }

        > p {
            padding: 0.5rem;
        }
    }
}

.firstword {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.lastword {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.wordListItemResult {
    display: flex;
    align-items: center;
    min-height: 60px;
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 8px;
    background-color: white;
}



.playButton {
    width: 24px;
    height: 24px;
    margin: 0 1rem;
    filter: invert(90%) sepia(0%) saturate(986%) hue-rotate(240deg) brightness(92%) contrast(103%);
    cursor: pointer;
}

.word {
    display: flex;
    align-items: center;
    width: inherit;
    cursor: pointer; 
    height: 100%;
    min-height: 60px;
    margin: 0 1rem;

    > div {
        display: flex;
        align-items: center;
        width: inherit;
    }
}   

.middleBorder {
    border-top: 1px solid Base.$light-500
}

.lastBorder {
    border-top: 1px solid Base.$light-500
}

.wordPinyin {
    @include Base.pinyin-med;
    border-right: 1px solid Base.$light-500;
    padding: 8px 0;
    width: 20%
}

.wordSimplified {
    @include Base.kaiti-sml;
    font-size: 20px;
    padding: 8px 0;
    padding-right: 1rem;
    width: 20%
}

.wordSimplifiedModal {
    width: 30%;
    border-right: 1px solid Base.$light-500;
}

.wordTranslation {
    @include Base.copy-sml;
    width: 65%;
    padding: 0.5rem 1rem;

}

.correct {
    border: 2px solid Base.$correct;
}

.incorrect {
    border: 2px solid Base.$wrong;
}

@media screen and (max-width: Base.$mobile-screen) {

    .wordListItem {
        min-height: auto;
    }

    .playButton {
        width: 1rem;
        height: 1rem;
        margin: 0 1rem;
    }

    .word {
        width: 100%;

        > img {
            margin: 0 0.5rem 0 0;
        }

        > p {
            padding: 0;
            border: none;
        }

        .wordTranslation {
            padding-top: 4px;
        }
    }

    .wordPinyin {
        width: 25%
    }
    
    .wordSimplified {
        width: 30%
    }
    
    .wordTranslation {
        width: 70%;
        padding: 0 0 0 0.5rem;
    
    }
}
