
@use '../../../App/Base.module.scss';

.cardContainerBody {
    border-radius: 20px;
    max-width: 856px;
}

.contentContainer {
    display: block;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

.quarterHeader {
    display: flex;
    justify-content: space-between;
    font-family: Base.$font-primary;
    padding: 0 0 1rem;

    .title {
        display: flex;
        
        > h2 {
            @include Base.h4-bold;
            padding-right: 1rem;
        }

        > p {
            @include Base.copy-med;
            color: Base.$light-500;
            padding-top: 4px;
        }
    }

    .moreButton {
        display: none;
        cursor: pointer;
        
        > span {
            @include Base.copy-med;
            color: Base.$light-500;
            padding-top: 19px;
        }
    
        > img {
            filter: invert(76%) sepia(0%) saturate(78%) hue-rotate(158deg) brightness(97%) contrast(89%);
            margin: 23.5px 0px 0px 6px;
            height: 12px;
            width: 6px;
        }
    }

    //Mobile see all button
    > p {
        @include Base.copy-bold;
        color: Base.$highlight-1;
    }
}

.moreButton:active {
    transform: scale(.98);
}

.cardContainerSlider {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
}



// ------------------------------------------------------------------

//Slider Elements

.slideLeft, .slideRight {
    display: none;
    border-radius: 100%;
    width: 50px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.30);
    opacity: 0.4;
}

.slideLeft {
    left: 12px;
}

.slideRight {
    right: 12px;
}

.slideLeft:hover, .slideRight:hover {
    background-color: rgba(255, 255, 255, 0.75);
    opacity: 1;
}

.slideLeft:active, .slideRight:active {
    transform: scale(.98);
    transition: transform 25ms;
}

.cardContainerBody:hover {
    .slideLeft, .slideRight {
        display: block;
    }
}

@media screen and (max-width: Base.$mobile-screen) {

    .title {
         > p {
            display: none;
         }
    }
}


@media screen and (min-width: Base.$mobile-screen) {

    .contentContainer {
        display: flex;
        overflow-x: scroll;
    }

    .quarterHeader {
        display: flex;
        justify-content: space-between;
        font-family: Base.$font-primary;
        padding: 32px 12px 0px 10px;
        
        .title {
            display: flex;
            
            > h2 {
                @include Base.h2em;
                padding-right: 2rem; 
            }
    
            > p {
                @include Base.h4em-med;
                padding-top: 1rem; 
                color: Base.$light-500;
            }
        }

        .moreButton {
            display: flex;
        }
        
        > p {
            display: none;
        }
    }
}