@use '../../../App/Base.module.scss';

.backdrop{
    @include Base.modalBackdrop;
    box-shadow: none; 
}

.deleteModal {
    display: flex;
    flex-direction: column;
    width: 592px;
    height: 200px;
    padding: 2rem;
    border-radius: 8px;
    background-color: Base.$light;
}

.warningMessage {
    display: flex;
    flex-direction: column;

    > p {
        @include Base.copy-med;
        text-align: center;
        padding-bottom: 2rem;
    }

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;

        > h4 {
            @include Base.h4-bold;
            text-align: center;
            padding-left: 1rem; 
        }
    }
}

.modalButtons {
    display: flex;
    justify-content: space-between;
}