
@use '../../../App/Base.module.scss';

// base word styles, and conditional highlights and blurs

.wordContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 0 4px 12px;

    > p {
        position: relative;
        cursor: pointer;
    }
    
    .blur {
        background-color: Base.$light-500;
        color: Base.$light-500;
        border-radius: 4px;
        user-select: none;
        padding: 0 4px;
    }
    
    .pinyinBlur {
        background-color: Base.$light-500;
        color: Base.$light-500;
        border-radius: 4px;
        user-select: none;
        padding: 4px;
    }
    
    .highlight {
        color: Base.$highlight-1;    
    }
    
    .highlightBlur {
        background-color: Base.$highlight-1;
        color: Base.$highlight-1;
    }    
}

.hanzi {
    @include Base.kaiti-med;
    padding: 0 4px;
}

//Same styles as above just slightly different for pinyin.
.pinyinSmall {
    @include Base.pinyin-med;
}

.pinyinBig {
    @include Base.pinyin-big;
    display: flex;
    justify-content: center;
    padding: 4px;
    min-width: 30px;
}

.pinyinHide {
    @include Base.pinyin-med;
    color: rgba(223, 31, 31, 0);
}

.highlightPinyinBlur {
    background-color: rgba(255, 255, 255, 0);;
    color: rgba(255, 255, 255, 0);
}

.highlightLearnMode { 
    color: Base.$highlight-1;
    padding: 0 4px;
    min-width: 30px;
}

.highlightLearnModePinyin { 
    color: Base.$highlight-1;
    padding: 4px;
    min-width: 30px;
}

.greenLine {
    border-bottom: 2px solid Base.$correct
}

.redLine {
    border-bottom: 2px solid Base.$wrong;
}

.translationBlur {
    @include Base.copy-reg;
    min-width: 30px;
    color: rgba(223, 31, 31, 0);
    padding-top: 0.5rem;
}

.translation { 
    @include Base.copy-reg;
    display: flex;
    justify-content: center;
    min-width: 30px;
    padding-top: 0.5rem;

}

.markdown {
    @include Base.kaiti-med;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    font-size: 32px;
}

.markdownPinyin {
    @include Base.kaiti-med;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    font-size: 24px;
}

.resultsPage {
    padding: 0 0 0 0.5rem;
}

.markdownContainer {
    display: flex;
    flex-direction: column;
}

.pinyinSpacerTop {
    height: 20px;
    width: 5px;
}

.markdownSpacer {
    height: 28px;
}

@media screen and (max-width: Base.$mobile-screen) {

    .wordContainer {
        padding-left: 4px;
    }

    .pinyinSmall {
        font-size: 12px;
    }

    .pinyinBig {
        font-size: 20px;
    }

    .hanzi {
        font-size: 20px;
    }

    .markdown {
        font-size: 20px;
    }

    .markdownPinyin {
        font-size: 20px;
    }

    .translation {
        font-size: 12px;
    }

    .translationBlur {
        font-size: 12px;
    }

    .pinyinSpacerTop {
        height: 15px;
    }

    .markdownSpacer {
        height: 23px;
    }

}



