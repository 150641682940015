@use '../../../App/Base.module.scss';


.speedModal {
    position: absolute;
    left: 0;
    bottom: 80px;
    width: 150px;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    z-index: 1;

    //experimental
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);

    > div {
        position: relative;

        > img {
            position: absolute;
            left: 8px;
        }

        > p {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
        }
    }

    > div:hover {
        background-color: Base.$light-200;
    }
}

.buttonSlow {
    padding: 12px 0 4px 40px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.buttonNorm {
    padding: 4px 0 4px 40px;
}

.buttonFast {
    padding: 4px 0 12px 40px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.speedModalMobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 228px;
    padding: 1.5rem 0;
    background-color: Base.$light;
    cursor: pointer;
    z-index: 1;

    box-shadow: 0px 3px 10px rgba(0, 0, 0, 1);

    > div {
        display: flex;
        justify-content: space-between;
        @include Base.copy-reg;
        padding: 4px 1.5rem;
        height: 36px;
        align-content: center;

        > p {
            align-self: center;
        }
    }

    > div:hover {
        background-color: Base.$light-200;
    }
}

.modalTitle {
    @include Base.copy-med;
    padding: 4px 1.5rem;
}