.checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 25px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .label .switchButton {
    content: '';
    position: absolute;
    top: 1.5px;
    left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .checkbox:checked + .label .switchButton {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .label:active .switchButton {
    //width: 60px;
  }