
@use "../../../App/Base.module.scss";

/* Some width and margin styles below are temporary during our UX testing; 
as some elements will not be implemented until after initial testing.  
Those items are marked with a //temporary comment */

.dashboard {
    max-width: 1100px;
}

.dashNav {
    display: flex;
    justify-content: center;
    height: 160px;
    width: 100%;
    margin: 1rem 0 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid Base.$light-200;;

    > aside {
        display: flex;
        height: 100%;
        width: 230px;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        > p {
            @include Base.copy-med;
        }

        > h3 {
            @include Base.h3em
        }
    }

}

#middleBox {
    border-left: 1px solid Base.$light-200;
    border-right: 1px solid Base.$light-200;
}

.dashContent {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 1rem 0 2rem;
}

.lessonList {
    grid-column: 1 / 2;
    width: auto;
    height: 100%;
    padding-right: 2rem;
}

.lessonListNoWords {
    grid-column: 1 / 3;
    padding: 0;
}

.sectHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 0 1rem;

    > h4 {
        @include Base.h4em;
    }

    > p {
        @include Base.copy-med;
        color: Base.$light-500;
        cursor: pointer;
    }
}

.itemList {
    display: flex;
    flex-direction: column;
    margin: 0 0 1rem;
    border-top: 1px solid Base.$light-200;
    border-bottom: 1px solid Base.$light-200;
}

.wordList { 

    > h4 {
        @include Base.h4em;
    }

    grid-column: 2 / 3;
}

@media screen and (max-width: Base.$mobile-screen) {

    .dashboard {
        grid-column: 1 / 5;
        grid-row: 2 / 3;
        overflow-y: scroll;
    }

    .dashContent {
        display: grid;
        grid-template-rows: auto auto;
        height: 100%;

        > aside {
            padding: 0;
        }
    }

    .lessonList {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        width: auto;
        height: 100%;
    }
    
    .wordList {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        padding-bottom: 4rem;
    }
    
    #middleBox {
        border-left: 1px solid Base.$light-200;
        border-right: 0px;
    }

    #resources {
        display: none;
    }

}

@media screen and (min-width: Base.$mobile-screen) {

    .dashboard {
        grid-column: 1 / 13;
        grid-row: 1 / 3;
    }
}

@media screen and (min-width: Base.$m-screen) {

}

@media screen and (min-width: Base.$l-screen) {

    .dashboard {
        grid-column: 1 / 13;
        grid-row: 1 / 3;
    }
}

@media screen and (min-width: Base.$xl-screen) {

    .dashboard {
        grid-column: 1 / 13;
        justify-self: center;
    }
}

