
@use '../../../App/Base.module.scss';



.flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > input, textarea {
        @include Base.copy-med;
        border: 1.5px solid Base.$light-200;
        border-radius: 4px;
        padding: 8px;
        resize: none;
        margin-bottom: 1rem;
    }
    
    > input::placeholder, textarea::placeholder {
        color: Base.$light-500;
    }
}

.label {
    @include Base.copy-med;
    padding-bottom: 0.5rem;
}

.description {
    height: 80px;
}

.tag {
    width: 376px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0 3rem;
}