
@use "../../App/Base.module.scss";

.page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: Base.$font-primary;
}

.userLogin {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 540px;
    height: 650px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);    
}

.authlogin {
    width: 100%; 
    padding: 0 10%;
    padding-top: 1rem;
    
    > button {
        margin-top: 2rem;
    }

    > p {
        padding-top: 1rem;
        text-align: center;
    
    }
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 240px;
    background-color: Base.$highlight-1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    > h1 {
        color: white;
        font-size: 32px;
    }
}
    
.loginForm {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: min-content;
    padding-top: 30px;
        
    > input {
        margin: 10px 0;
        padding: 0 20px;
        width: 100%;
        height: 48px;
        background-color: #F2F2F2;
        outline: none;
        border: Base.$highlight-1 2px solid;
        border-radius: 5px;
    }

    > p {
        padding-top: 1rem;
        cursor: pointer;
    }

    > p:hover {
        color: Base.$highlight-1;
    }
} 

.registerLink {
    padding-top: 24px;
    
    > a {
        color: Base.$highlight-1;
    }
}

.loginLink {
    padding-top: 24px;
    color: Base.$highlight-3;
}

.clearButton {
    position: absolute;
    right: 15px;
    top: 52px;
    width: 24px;
    cursor: pointer;
}

.blur {
    position: absolute;
    right: 15px;
    width: 22px;
}


@media screen and (max-width: Base.$mobile-screen) {
    
    .page {
        align-items: flex-start;
    }

    .userLogin {
        width: inherit;
        height: inherit;
        border-radius: 0;
        box-shadow: none;
    }

    .logo {
        border-radius: 0;
        
        > h1 {
            font-size: 24px;
        }
    }
}

.blur {
    position: absolute;
    right: 15px;
    top: 189px;
    width: 22px;
}