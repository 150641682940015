@use '../../App/Base.module.scss';

.button {
    display: flex;
    padding: 4px 8px;
    border: 1.5px solid Base.$light-200;
    border-radius: 4px;
    
    > img {
        padding-right: 4px;
    }

    > p {
        @include Base.copy-sml;
        color: Base.$dark-700;
    }
}