@use '../../../App/Base.module.scss';

.wordEditorPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.searchInput {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    position: relative;

    > label {
        margin-right: 1rem;
    }

    .searchResults {
        position: absolute;
        top: 40px;
        max-height: 300px;
        width: 300px;
        padding: 0 0.5rem;
        background-color: white;
        overflow: scroll;
    }

    .wordListItem {
        display: flex;
        padding: 0.5rem 0;
        border-bottom: 1px solid grey;
        cursor: pointer;

        .kaiti {
            @include Base.kaiti-xs;
            width: 25%
        }

        .pinyin {
            @include Base.copy-reg;
            width: 50%;
        }
    }

    .wordListItem:hover {
        background-color: wheat;
    }
}

.translations {
    width: 270px;
}

.wordList{
    height: 100%;
}

.wordItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.5rem;
    border-bottom: 1px solid grey;

    > div {
        display: flex;
        
        > div {

            > textarea {
                margin-right: 0.5rem;
            }
        }
    }


    > button {
        border: 1.5px solid Base.$highlight-1;
    }
}


.deleteButton {
    max-width: 24px;
    cursor: pointer;
    margin-left: 1rem
}

.wordPreview {
    @include Base.kaiti-sml;
    min-width: 110px;
    max-width: 110px;
    padding-top: 0.75rem;
    padding-right: 0.5rem;
    font-size: 20px;
}

.input {
    width: 15%;
}

.transInput {
    width: 55%;
}

.wordItemFields {
    width: 85%;
}

.buttons {
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 0.5rem; 
    width: 15%;

    > button {
        max-height: 32px;
    }
}


