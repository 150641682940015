
@use '../../../App/Base.module.scss';

.wordTypeSwitch {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #FFFFFF;
    cursor: pointer;
    width: min-content;
    margin-top: 0.5rem;

    > p { 
        padding: 4px 0px;
        width: 60px;
        text-align: center;
    }

    .hanzi {
        font-family: Base.$font-hanzi;
        line-height: 125%;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }   
    
    .english {
        @include Base.copy-sml;
        line-height: 150%;
    }
    
    .pinyin {
        @include Base.copy-sml;
        line-height: 150%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .highlight {
        background-color: Base.$highlight-1;
        color: white;
    }
}