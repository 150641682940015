
@use '../../App/Base.module.scss';

.videoModal {
    position: absolute;
    border-radius: 8px;
    z-index: 1;
    max-height: 600px;
    max-width: 800px;
    height: 100%;
    width: 100%;
    
    > video {
        border-radius: 8px;
    }  
    
    > iframe {
        border-radius: 8px;
        border: 0;
    }
}

.videoModalTutorial {
    position: absolute;
    bottom: 100px;
    right: 32px;
    border-radius: 8px;
    z-index: 1;
    max-height: 243px;
    max-width: 432px;
    height: 100%;
    width: 100%;
    
    > video {
        border-radius: 8px;
    }  

}

.videoTutorial {
    max-height: 243px;
    max-width: 432px;
    height: 100%;
    width: 100%;
}

.video {
    max-height: 600px;
    max-width: 800px;
    height: 100%;
    width: 100%;
}

.closeButton {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 1;  
}

.closeButton:hover {
    transform: scale(1.05);
    transition: transform 1ms;
}

@media screen and (max-width: Base.$m-screen) {

    .videoModalTutorial {
        right: auto;
    }
}

