
@use '../../../App/Base.module.scss';

// This page stores top level grid details for the lesson page

.pageGrid {
    display: grid;
    grid-template-rows: auto 80px;
    gap: 0 0.5rem;
    width: 100%;
    height: 100%;
    outline: none;
}

.lessonContent {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    width: inherit;
    font-family: Base.$font-primary;
    overflow-y: auto;
    
    > h1 {
        padding-top: 20px;
    }
}

.audioControlContainer {
    grid-column: 1 / 5;
    grid-row: 2 / 3;
}

.sidebar {
    display: none;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

@media screen and (max-width: Base.$mobile-screen) {

    .pageGrid {
        grid-template-rows: 64px auto 90px;
    }

    .lessonContent {
        grid-row: 2 / 4;
    }

    .listenModeStyle {
        grid-row: 2 / 3;
    }

    .audioControlContainer {
        grid-row: 3 / 4;
    }

}

@media screen and (min-width: Base.$mobile-screen) {

    .lessonContent {
        grid-column: 1 / 13;
    }

    .audioControlContainer {
        grid-column: 1 / 13;
    }
}

@media screen and (min-width: Base.$l-screen) {

    .lessonContent {
        grid-column: 2 / 12;
    }

    .audioControlContainer {
        grid-column: 2 / 12;
    }

}

@media screen and (min-width: Base.$xl-screen) {

    .lessonContent {
        grid-column: 2 / 12;
    }
    
    .audioControlContainer {
        grid-column: 2 / 12;
    }
}

@media screen and (min-width: Base.$xxl-screen) { 

    .lessonContent {
        grid-column: 3 / 11;
    }
    
    .audioControlContainer {
        grid-column: 3 / 11;
    }
}