@use '../../../App/Base.module.scss';

.grammerContainer {
    width: 100%;
    background-color: Base.$light;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

    .grammarTitle {
        @include Base.copy-bold;
        padding-bottom: 1rem;
    }

    .grammarDescription {
        padding-bottom: 1rem;
    }

    .grammarExplanation {
        @include Base.copy-bold;
        text-align: center;
    }
}

.taglist {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link {
    @include Base.nav-btn;
    height: min-content;
    color: Base.$light;
    background-color: Base.$highlight-1;
    border-radius: 20px;
    padding: 0.25rem 0.5rem;
}

.bold {
    @include Base.copy-bold;
}

.orangeText {
    @include Base.copy-med;
    color: Base.$highlight-1;
}

.purpleText {
    @include Base.copy-med;
    color: Base.$grammar-aid-color;
}

.tealText {
    @include Base.copy-med;
    color: Base.$highlight-6;
}

.normal {
    @include Base.copy-med;
}
