@use '../../../App/Base.module.scss';


.resourcesPage {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
}

.resourceForm {
    grid-column: 1 / 2;
}

.resourcePreview {
    grid-column: 2 / 3;
    padding: 0 1rem 1rem; 

    > p {
        @include Base.copy-med;
        padding-bottom: 0.5rem;
    }
}

.buttons {
    grid-column: 1 / 3;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0 2rem;
}

.bottomForm {
    display: flex;
}

.checkBoxContainer {
    display: flex;
    padding-bottom: 1rem;
}

.checkBox {
    @include Base.copy-med;
    display: flex;
    padding-right: 1rem;
    align-items: end;

    > img {
        padding-right: 0.25rem;
    }

    > input {
        display: none;
    } 
}