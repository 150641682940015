
@use '../../App/Base.module.scss';

.topBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 0;
    border-bottom: 1px solid #f2f2f2;
    background-color: Base.$highlight-1;

}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 1rem;
}

.bottom {
    width: 100%;
    height: 18px;

     > div {
        height: inherit;
        width: inherit;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        background-color: Base.$bg-light;
    }
}

@media screen and (min-width: Base.$mobile-screen) {

    .topBar {
        display: none;
    }
}