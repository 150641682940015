@use '../../../App/Base.module.scss';

.listItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 50px;
    padding: .75rem 0;
    border-top: 1px solid Base.$light-200;
    border-bottom: 1px solid Base.$light-200;
    cursor: pointer;

    .listItemInfo {
        display: flex;

        > img {
            height: 50px;
            width: 60px;
            object-fit: cover;
            border-radius: 4px;
        }
   
        > div {
            padding: 0 1rem;
    
            > h5 {
                @include Base.copy-bold;
            }
    
            
        }
    }
}

.listItemInfoBottom {
    display: flex;
    padding-top: 0.5rem;

    > p {
        @include Base.copy-med;
        color: Base.$dark-700;
        margin-right: 1rem;
    }
    
    .lessonTag {
        color: Base.$light;
        padding: 1px 0.5rem;
        background-color: Base.$highlight-1;
        border-radius: 4px;
    }
}



.listItem:hover {
    background-color: Base.$light;
}

.indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 4px;
    min-width: 76px;
    width: 15%;
    max-height: 50px;
    height: 50px;
    margin-right: 1rem;
    background-color: red;

    > p {
        @include Base.copy-bold;
        color: Base.$light;
    }
}

.listen {
    background-color: Base.$highlight-3;
}

.learn {
    background-color: Base.$highlight-2;
}

.test {
    background-color: #009962;
}

.review {
    background-color: Base.$light-500;
}

@media screen and (max-width: Base.$m-screen) {

    .listItemInfo {
        width: 90%;
    }

    .indicator {
        align-self: center;
        border-radius: 100%;
        min-width: 24px;
        width: 24px;
        height: 24px;
        margin: 0;

        > p {
            font-weight: 500;
        }
    }
}
