@use '../../App/Base.module.scss';

.devpage {
    display: flex;
    justify-content: center;
    align-items: center;
}




