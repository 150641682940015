@use '../../App/Base.module.scss';
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 16px;
    min-width: 16px;
    border-radius: 100px;

    > img {
        filter: Base.$white-svg;
        width: 14px;
        height: 14px;
    }
}

.check {
    background-color: Base.$highlight-6;
}

.start {
    background-color: Base.$highlight-6;
}

.next {
    border: 3px solid Base.$highlight-6;
}

.hide {
    border: 3px solid Base.$light-200;
}
