@use '../../../App/Base.module.scss';

.option {
    @include Base.copy-med;
    padding: 8px 21px;
    border-radius: 8px;
    cursor: pointer;
}

.unactiveOption {
    background-color: white;
}

.modalStyle {
    background-color: Base.$bg-light;
}
   
.activeOption {
    @include Base.copy-med;
    color: white;
    background-color: Base.$nav-background;
    border-radius: 8px;
}

.blockedClosed {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;   
    background-color: white;
}

.blockedOpen {
    display: flex;
    align-items: center;
    //background-color: Base.$light-200;
    padding-right: 8px;
    border-radius: 8px;
    cursor: pointer;
    
    > option {
        @include Base.copy-med;
        padding: 8px 24px;
    }
}

.blockedImg {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 7px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: Base.$bg-light;
    cursor: pointer;

    > img {
        height: 12px;
        width: 8px; 
    }

    .blockedImgOpen {
        transform: scaleX(-1);
    }
}

.listOption {
    position: relative;
    padding: 4px 0 4px 40px;

    > img {
        position: absolute;
        left: 8px;
    }

    > p {
        @include Base.copy-reg;
    }
}

.listOption:hover {
    background-color: Base.$light-200;
}

.listTop {
    padding: 12px 0 4px 40px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.listBottom {
    padding: 4px 0 12px 40px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}