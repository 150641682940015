@use '../../PageGrid.module.scss';
@use './LessonGrid.module.scss';
@use '../../../App/Base.module.scss';


.pageGrid {
    overflow-y: auto;
}

.instructionMessage {
    @include Base.h2em;
    display: flex;
    justify-content: center;
    padding-top: 32px;
    text-align: center;
}

.phrases {
    padding: 0 5%;

    > h1 {
        font-family: Base.$font-primary;
        width: max-content;
        margin: 20px auto;
    }
}

.phraseFlex {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    margin: 1rem 0;
    padding: 0.5rem 0;
    padding-right: 1rem;

    background-color: white;
    box-shadow: 0px 4px 1px rgba(181, 181, 181, 0.25); 
       
    .playActive {
        filter: invert(57%) sepia(72%) saturate(491%) hue-rotate(348deg) brightness(93%) contrast(96%);
    }
    
    // This targets the PhraseBlock to align the words and markdowns
    > div {
        align-items: center;
    }
}

/* these next 3 are For the spacing of the play button */

.phrasePlayBtn {
    align-self: flex-start;
    max-width: 20px;
    min-width: 20px;  
    cursor: pointer;  
}

.activeButton {
    filter: invert(59%) sepia(90%) saturate(623%) hue-rotate(339deg) brightness(94%) contrast(95%);
}

.hanzi {
    padding-top: 18px;
}

.pinyin {
    padding-top: 14px;
}

.bothTypes {
    padding-top: 36px;
}

.highlightStyle {
    background-color: white;
    box-shadow: 0px 4px 1px rgba(181, 181, 181, 0.25); 
}

.transcriptModal {
    position: absolute;
    right: 0;
    bottom: 100px;
    width: 250px;
    max-width: 250px;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: 8px;
    padding: 24px 32px 24px 24px;
    background-color: white;
    font-family: Base.$font-primary;

    //experimental
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);

    > img {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
    }

    > img:hover {
        @include Base.onHover;
    }

    > h5 {
        @include Base.copy-bold;

    }
}

.transcriptPhrase {
    @include Base.copy-reg;
    padding-top: 16px;
}

.selected {
    color: Base.$highlight-3;
}

.mobilePageNav {
    padding-bottom: 2rem;
}

@media screen and (max-width: Base.$mobile-screen) {

    .phrases {
        padding: 0 0.5rem 0 0;
    }

    .phrasePlayBtn {
        margin-left: 0.5rem;

    }

    .phraseFlex {
        padding: 0.25rem 0;
    }

    .instructionMessage {
        @include Base.h3em;
        padding: 0 .5rem;
    }

    .hanzi {
        padding-top: 0.5rem;
    }
    
    .pinyin {
        padding-top: 0.7rem;
    }
    
    .bothTypes {
        padding-top: 1.5rem;
    }

    
}

@media screen and (min-width: Base.$mobile-screen) {

    .phrasePlayBtn {
        margin-left: 1.5rem;
        //margin: 0 4px 0 1.5rem;
    }

    .mobilePageNav{
        display: none;
    }
}






