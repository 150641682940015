@use '../../../App/Base.module.scss';



.listContainer {
    padding-bottom: 4rem;
}

.lessonTable {
    border-collapse: collapse;
    width: 100%;

    > thead {
        > tr {
            > th {
                @include Base.copy-bold;
                padding-bottom: 0.5rem;
                text-align: start;
            }
        }
    }

    .titleHeader {
        padding-left: 1rem;
    }
}

.lessonList {
    width: 100%;
}

.listItem {
    height: 4rem;
    width: 100%;
    border-top: 1px solid Base.$light-200;
    border-bottom: 1px solid Base.$light-200;
    
    > td {
        @include Base.copy-med;
        cursor: pointer;
        width: 125px;
        min-width: 125px;
        max-width: 125px;
    }

    .title {
        width: 45%;
        min-width: 45%;
        max-width: 45%;
        padding-left: 1rem;
    }

    .lessonImg {
        width: 96px;
        min-width: 96px;
        max-width: 96px;

        > img {
            height: 3rem;
            object-fit: cover;
            width: 100%;
        }
    }
    
    .deleteButton {
        min-width: 50px;
        max-width: 50px;
        width: 50px;
        
        > img {
            width: 24px;
            height: 24px;
            margin-left: 14px;
        }
    }
}

.listItem:hover {
    background-color: Base.$light-200;
}

.moreButton {
    display: flex;
    justify-content: center;
    padding: 1rem 0 0;
}

