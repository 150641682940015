@use '../../../App/Base.module.scss';

.resultContainer {
    display: flex;
    flex-direction: column;    
}

.resultListItem {
    position: relative;
    display: flex;
    margin-bottom: 24px;
    border-radius: 10px;
    padding: 1rem 4rem 1rem 3rem;
    background-color: white;  
}

.playButton {    
    position: absolute;
    left: 1rem;
    top: 1.75rem;
    height: 24px;
    width: 16px;
    cursor: pointer;
}

.checkType {
    position: absolute;
    height: 32px;
    width: 32px;
    right: 1rem;
    top: 1.5rem;
    //align-self: center;
}

.bothTypes {
    top: 2.75rem;
}

.pinyin {
    top: 1.5rem;
}

.resultContent {
    position: relative;
    display: flex;
    flex-direction: column;
}

.answerStyle {
    padding-left: 4px;

    > p {
        @include Base.copy-reg;
        padding: 4px 0
    }

    > b {
        @include Base.copy-bold;
    }

    > h4 {
        @include Base.copy-bold;
        padding: 8px 0; 
        margin-top: 8px;
        width: 100%;
        border-top: 1px solid Base.$light-200;
    }
}

.answerCorrect {
    color: Base.$correct;
}

.userAnswer {
    color: Base.$dark-700;
}

.correct {
    border: 2px solid Base.$correct;
}

.incorrect {
    border: 2px solid Base.$wrong;
}

@media screen and (max-width: Base.$mobile-screen) {

    .resultContent{
        position: relative;
        display: flex;
        flex-direction: column;

        > div {
            > div {
                padding: 0;
                
            }
        }
    }

    .resultListItem {
        padding: 1rem 48px 1rem 40px;
    }

    .playButton {    
        position: absolute;
        left: 1rem;
        top: 1.25rem;
        height: 24px;
        width: 16px;
        cursor: pointer;
    }

    .bothTypes {
        top: 2rem;
    }

    .pinyin {
        top: 1.25rem;
    }

    .answerStyle {
        padding-left: 4px;
    
        > p {
            font-size: 14px;
            padding: 4px 0
        }
    
        > b {
            font-size: 14px

        }
    
        > h4 {
            font-size: 14px;
            padding: 8px 0; 
            margin-top: 8px;
            width: 100%;
            border-top: 1px solid Base.$light-200;
        }
    }

}