
@use '../../../App/Base.module.scss';

.wordSimple {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    > p {
        text-align: center;
    }

    .pinyinPlay {
        top: 28px;
    }

    .bothtypePlay {
        top: 50px;
    }
}

.smallword {
    @include Base.pinyin-big
}

.bigword {
    @include Base.copy-med;
    font-size: 48px
}

.longbigword {
    @include Base.copy-med;
    font-size: 36px;
}

@media screen and (max-width: Base.$mobile-screen) {

    .wordSimple {
        padding-top: 1rem;
    }

}