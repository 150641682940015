
@use '../../../Pages/UserWords/_styles/WordStats.module.scss';
@use '../../../App/Base.module.scss';

.wordRows {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-bottom: 1rem;
}

.modalStyle {

    > li {
        background-color: Base.$bg-light;
    }
}

.showMore {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.75rem 0 1rem;
}
