
@use '../../../App/Base.module.scss';

.lessonPageBody {
    grid-column: 1 / 5;
    grid-row: 2 / 3;
    overflow: scroll;
}

.headerBreadCrumb {
    display: flex;

    > h3 {
        @include Base.h4-bold;
        color: Base.$light;
    }
}

.lessonCarousels {
    padding-bottom: 40px;
}

.filterContainer {
    //mobile
    position: absolute;
    left: 0;
    top: 88px;
    min-width: inherit;
    font-family: Base.$font-primary;
}

.clearFilters {
    display: flex;
    justify-content: center;
    padding: 5px 0;
}

.featuredHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 0 0 6px;

    > h1 {
        @include Base.h1em;
        font-family: Base.$font-primary;
    }

    > button {
        display: none;
    }
}

.lessonWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        display: block;
        align-items: flex-end;
        justify-content: space-between;
    }
    
    > body {
        display: flex;
        flex-wrap: wrap;
        max-width: 870px;
        padding-bottom: 3rem;
        
        /* This effects the lesson cards */
        > div {
            margin: 0.5rem;
        }
    }
}

.sectionTitle {
    display: none;
    font-family: Base.$font-primary;
    padding: 22px 10px 0;
}

.showMore {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 3rem;
}

.noLessonMessage {
    padding: 10px 24px;;
    font-family: Base.$font-primary;
    color: Base.$light-500;
}

.featuredLessons {
    margin-top: 22px;
}

.backdrop {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: Base.$mobile-screen) {

    .lessonWrap {
        > body {
            > div {
                margin: 0 0 1rem ;
            }
        }
    }
}

@media screen and (min-width: Base.$mobile-screen) {

    .headerMobile {
        display: none;
    }

    .lessonPageBody {
        grid-column: 1 / 13;
        grid-row: 1 / 3;
        height: 100%;
        overflow: initial;
    }

    .featuredHeader {

        > h1 {
            padding-right: 32px;
        }

        > button {
            display: flex;
        }
    }

    .lessonWrap {

        > div {
            display: flex
        }
    }

    .sectionTitle {
        display: block;
    }

    .filterContainer {
        position: absolute;
        top: Base.$headerHeight;
    }
}

@media screen and (min-width: Base.$l-screen) {

    .filterContainer {
        left: 0;
    }
}

@media screen and (min-width: Base.$l-screen) {
    
    .lessonPageBody {
        grid-column: 1 / 13;
        display: flex;
        justify-content: center;
    }

    .filterContainer {
        position: absolute;
        top: Base.$headerHeight;
        left: Base.$navBarWidth;
    }
}

@media screen and (min-width: Base.$xl-screen) {

    .lessonPageBody {
        grid-column: 3 / 13;
    } 

    .featuredHeader {
        justify-content: flex-start;

        > button {
            display: none;
        }
    }
    
    .filterButton {
        display: none;
    }

    .filterContainer {
        display: flex;
        justify-content: center;
        position: fixed;
        min-width: inherit;
        font-family: Base.$font-primary;
        grid-column: 1 / 3;
    }

    .filterContainer {
        top: Base.$headerHeight + 88;
        left: Base.$navBarWidth + 44;
    }

    .filter {
        height: min-content;
        box-shadow: none;
    }
}

@media screen and (min-width: Base.$xxl-screen) {

    .lessonPageBody {
        display: flex;
        justify-content: center;
        grid-column: 3 / 11;
    }
}
