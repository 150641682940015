@use '../../../App/Base.module.scss';


.multimediaResource {
    width: 100%;
    padding: 12px;
    background-color: Base.$light;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.tags {
    display: flex;
    padding-bottom: 0.5rem;

    > p {
        @include Base.copy-sml;
        font-size: 12px;
        display: flex;
        padding: 0px 4px 1px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-right: 4px;
        border-radius: 4px;
        border: 1px solid Base.$light-500;
    }
}

.resourceContainer {
    display: flex;
    justify-content: space-between
}

.resrouceBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
        @include Base.copy-med;
        padding-bottom: 0.5rem
    }

    .description {
        @include Base.copy-med;
        font-size: 12px;
    }
}

.coverImage {
    max-width: 64px;
    min-width: 64px;
    max-height: 64px;
    min-height: 64px;
    margin-left: 1rem;
    border-radius: 8px;
}

.noCoverImage {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    max-width: 64px;
    min-width: 64px;
    max-height: 64px;
    min-height: 64px;
    margin-left: 1rem;
    border-radius: 8px;
    background-color: Base.$light-200;
}