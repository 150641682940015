
@use '../../PageGrid.module.scss';
@use '../../../App/Base.module.scss';


.headerBreadCrumb {
    display: flex;

    > h3 {
        @include Base.h4-bold;
        color: Base.$light;
    }

    > div {
        display: flex;

        > img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(285deg) brightness(101%) contrast(102%);
        }

        > h3 {
            @include Base.h4-bold;
            color: Base.$light;
        }
    }
}

.fcListContent {
    overflow: hidden;
}

.fcInfo {
    display: flex;
    justify-content: space-between;
    height: 132px;
    width: 100%;
    max-width: 830px;
    padding-top: 1rem;

    > aside{
        width: 49%;
        border-radius: 8px;
        background-color: Base.$light;
        padding: 16px;
    }
}

.vocabDue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > p {
        @include Base.copy-med;
    }


}

.vocabDueBottom {
    display: flex;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: flex-end;

        > h2 {
            @include Base.h2em;
/*             padding-left: 32px; */
        }

        > p {
            @include Base.copy-med;
            padding: 8px;
        }

    }
    
    > button {
        max-height: 44px;
    }
}

.vocabLibrary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > p {
        @include Base.copy-med;

    }

    > div {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        h2 {
            @include Base.h2em;
        }

        > p {
            @include Base.copy-med;
            padding: 8px;
        }
    }
}

@media screen and (max-width: Base.$mobile-screen) {

    .fcInfo {
        flex-direction: column;
        padding: 0;

        > aside {
            width: 100%;
            min-height: 96px;
            margin-bottom: 1rem;
            padding: 0.5rem 1rem;
            box-shadow: Base.$boxShadow;
        }
    }
    
    .fcListContent {
        grid-column: 1 / 5;
        grid-row: 2 / 3;
    }

    .vocabDueBottom {
        > div {
            > h2 {
                padding: 0;
            }
        }
    }

    .vocabLibrary {

        > div {
            justify-content: flex-start;
        }
    }

}

@media screen and (min-width: Base.$mobile-screen) {
    


    .fcListContent {
        grid-column: 1 / 13;
        //justify-self: center;
    }
}

@media screen and (min-width: Base.$m-screen) {

    .vocabDueBottom {
        > div {
            >h2 {
                padding-left: 32px;
            }
        }
    }


    .fcListContent {
        grid-column: 1 / 13;
    }
}

@media screen and (min-width: Base.$l-screen) {

    .fcListContent {
        grid-column: 2 / 12;
    }
    
}

@media screen and (min-width: Base.$l-screen) {

    .fcListContent {
        grid-column: 2 / 12;
        //max-width: 630px; 
    }
}

@media screen and (min-width: Base.$xl-screen) {

    .fcListContent {
        grid-column: 3 / 11;
        justify-self: center;
        width: 830px;
    }
}

