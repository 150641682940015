@use '../../App//Base.module.scss';

.tag {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4rem;
    width: fit-content;
    padding: 4px 8px ;
    margin-top: 5px;
    border-radius: 4px;
    line-height: 120%;
    color: white;
    word-break: normal;
    background-color: Base.$highlight-1;  

    > p {
        @include Base.copy-med;
        width: 100%;
    }
}