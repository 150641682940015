@use '../../App/Base.module.scss';

.small {
    @include Base.kaiti-sml;
}

.medium {
    @include Base.kaiti-med;
}

.large {
    @include Base.kaiti-med;
    font-size: 48px
}
