@use '../../../App/Base.module.scss';


.defaultStyle {
    display: flex;
    flex-direction: column;
    
    > textarea, input {
        margin-bottom: 1rem;
    }

    > label {
        @include Base.copy-med;
        padding-bottom: 0.5rem;
    }
}

.inputCreate {

    > textarea, input {
        height: 38px;
        width: 100%
    }
}

.inputBox {
    @include Base.copy-med;
    border: 1.5px solid Base.$light-200;
    border-radius: 4px;
    padding: 8px;
    resize: none;
}

.inputBox::placeholder {
    color: Base.$light-500;
}

.changeText {
    display: flex;
    margin-bottom: 0.5rem;

    > option {
        @include Base.copy-med;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: Base.$light;
        margin-right: 0.5rem;
        cursor: pointer;
    }

    > option:hover {
        transform: scale(1.01);
    }
    
    .boldText {
        @include Base.copy-bold;
    }
    
    .orangeText {
        color: Base.$highlight-1;
    }
    
    .purpleText {
        color: Base.$grammar-aid-color;
    }
    
    .tealText {
        color: Base.$highlight-6;
    }
}

