@use "../../../App/Base.module.scss";


.worksheet {
    min-height: 276px;
    width: 176px;
    background-color: Base.$light;   
    border-radius: 8px;
}

.coverImage {
    width: 100%;
    height: 176px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.noCoverImage {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 176px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: Base.$light-200;
}

.worksheetBottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    min-height: 100px;
    padding: .25rem .5rem .5rem;
}

.type {
    @include Base.copy-sml;
    color: Base.$highlight-1
}

.title {
    @include Base.copy-sml;
    padding-bottom: 1rem;
}