@use '../../App/Base.module.scss';

.menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 300px;
    height: min-content;
    border-radius: 8px;
    background-color: white;
    z-index: 1;

    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);


    > div {
        display: flex;
        justify-content: space-between;
        padding: 8px 15px;

        > p {
            font-size: 16px;
            font-family: Base.$font-primary;
        }
    }
}

.fcStyles {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.blurLevel {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 32px;
    padding-top: 2px;
    margin-right: 4px;
    font-family: Base.$font-primary;
    border-radius: 8px;
    border: solid hsla(0, 0%, 65%, 1) 2px;
    cursor: pointer;
}

#levelSelect {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    width: 40px;
    right: -40px;
    bottom: 18px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;

    > p {
        text-align: center;
        font-family: Base.$font-primary;
        font-size: 16px;
        padding: 4px;
    }
}

.activeButton {
    background-color: hsla(0, 0%, 85%, 1)
}

.inactiveButton {
    background-color: rgba(255, 255, 255, 0); 
    color: hsla(0, 0%, 49%, 1); 
}

.inactiveButton:hover {
    background-color: hsla(0, 0%, 85%, 1);
    color: black;
}

.speedControls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    cursor: pointer;
    
    > p {
        font-size: 20px;
        padding: 18px;
    }

    > div {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 0;
        bottom: 100px;
        width: 150px;
        background-color: white;
            
        > button {
            width: 100%;
            height: 32px;
            border: 0;
            padding: 3px 5px;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.mobileStyle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem 1.5rem ;
    background-color: Base.$light;
    z-index: 1;

    box-shadow: 0px 3px 10px rgba(0, 0, 0, 1);

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;

        > p {
            @include Base.copy-reg;
        }
    }
}

.modalTitle {
    padding: 1rem 0;
    @include Base.copy-med;
}

.blockButtons {
    display: flex;
    padding-top: 1rem;
    border-top: 1px solid Base.$light-200;

    > button {
        margin-right: 0.5rem;
    }
}