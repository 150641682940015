@use '../../../App//Base.module.scss';


.filterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
}

.filterOptions {
    position: relative;
    display: flex;
    height: 36px;
    width: min-content;
    border-radius: 8px;
    background-color: white;
}

.modalStyle {
    background-color: Base.$bg-light;
}

.filterOptionsMobile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.filterDropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 140px;
    height: 32px;
    padding: 6px 1rem;
    border-radius: 8px;
    background-color: Base.$highlight-1;
    background-color: #E98B2A;
    cursor: pointer;

    > p {
        @include Base.copy-bold;
        color: Base.$light;
    }
}

.filterDropdownModal {
    position: absolute;
    width: 140px;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    z-index: 1;

    //experimental
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    background-color: Base.$light;
    top: 50px;
    z-index: 1;

    .listOption {
        position: relative;
        padding: 4px 0 4px 40px;

        > img {
            position: absolute;
            left: 8px;
        }

        > p {
            @include Base.copy-reg;
        }
    }

    .listOption:hover {
        background-color: Base.$light-200;
    }

    .listTop {
        padding: 12px 0 4px 40px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .listBottom {
        padding: 4px 0 12px 40px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}


