@use "../../App/Base.module.scss";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    @include Base.copy-bold;
    color: black;
}

.wrapper:active {
    transform: scale(.98);
}

.blue {
    color: Base.$light;
    background-color: Base.$highlight-6;
}

.orange {
    color: Base.$light;
    background-color: Base.$highlight-1;
}

.blueClear {
    color: black;
    border: 2px solid Base.$highlight-6;
    background-color: rgba(255, 255, 255, 0);
}

.orangeClear {
    color: black;
    border: 2px solid Base.$highlight-1;
    background-color: rgba(255, 255, 255, 0);
}

// hover styles

.blue:hover {
    color: Base.$light;
    background-color: Base.$highlight-5;
}

.orange:hover {
    color: Base.$light;
    background-color: Base.$highlight-3;
}

.blueClear:hover {
    border: 2px solid Base.$highlight-6;
    background-color: rgba(18, 194, 194, 0.25);
}

.orangeClear:hover {
    border: 2px solid Base.$highlight-1;
    background-color: rgba(233, 139, 42, 0.25);
}

.block {
    color: Base.$light;
    background-color: Base.$light-200;
    cursor: not-allowed;
}

.block:active { 
    transform: none;
}

.pageNav {
    background-color: Base.$bg-light;
    border: 2px solid Base.$light-200;
}

.pageNavSelect {
    background-color: Base.$bg-light;
    border: 2px solid Base.$highlight-6;
}
