@use '../../PageGrid.module.scss';
@use './LessonGrid.module.scss';
@use '../../../App/Base.module.scss';


.instructionMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;

    > h2 {
        @include Base.h2em;
        text-align: center
    }

    > h4 {
        justify-self: center;
        @include Base.h4em-med;
        color: Base.$dark-700;
        padding-bottom: 2rem;
    }

}

.phraseContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 5%;
    border-radius: 10px;
    overflow-y: scroll;

    > h4 {
        justify-self: center;
        @include Base.h4em-med;
        color: Base.$dark-700;
        padding-bottom: 2rem;
    }

// garbage
    .wordCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: max-content;
    }
}

.transContainer {
    padding: 0 5% 2rem;
}

.correctTag {
    display: flex;
    align-items: center;
    height: 32px;
    color: Base.$correct;
    font-weight: 700;
}

.transVisible, .transHidden {
    @include Base.h4em-med;
}

.transHidden {
    filter: blur(10px);
}

.hr {
    width: 30%;
    margin: 20px;
    border-top: 5px dotted black;
}

// ALL USER INPUT AND CONTROL STYLES ACCESSED DURING TESTING
.controlsContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; 
    padding: 0 5%;
}

.closeButton {
    position: absolute;
    top: 18px;
    right: 3rem;
    cursor: pointer;
}

.inputDiv {
    @include Base.h4em-med;
    max-height: 58px;
    width: 100%;
    padding: 1rem 2rem 0 1rem;
    border: 2px solid Base.$light-200;
    border-radius: 4px;
    resize: none;
    margin-bottom: 1rem;
}

.inputDiv::placeholder {
    @include Base.h4em-med;
    color: Base.$light-200;
}

.inputDiv:focus {
    border: 2px solid Base.$highlight-1;
}

.userInput {
    align-items: center; 
    min-height: 58px;
    width: 100%;
    padding: 1rem 2rem 0 1rem;
    background-color: Base.$light;
    border: 2px solid Base.$light-200;
    border-radius: 4px;
    margin-bottom: 1rem;
    
    > p {
        @include Base.h4em-med;
        display: flex;
        //justify-content: center;
        word-break: break-word;
    }
}

.testbuttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    padding: 0 10%;

    > button {
        width: 48%;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: Base.$mobile-screen) {

    .instructionMessage {
        padding: 0;

        > h2 {
            @include Base.h3em;
            padding: 0 .5rem;
            //maybe just remove it?
            display: none;
        }

        > h4 {
            padding: 0;
        }
    }

    .phraseContainer {
        padding: 0;

        > h4 {
            display: none;
        }
    }

    .controlsContainer {
        width: 100%;
    }

    .closeButton {
        right: 28px;
    }

    .userInput {
        width: 100%;
        min-height: 7rem;
        margin-bottom: 1.1rem;
    }

    .inputDiv {
        width: 100%;
        min-height: 7rem;

        > input {
            width: 100%;
        }
    }

    .testbuttons {
        padding: 0;
    }


}

@media screen and (min-width: Base.$mobile-screen) {

    .instructionMessage {
        
        > h4 {
            display: none;
        }
    }
}

@media screen and (max-width: Base.$l-screen) {

    .instructionMessage {

        > h2 {
            @include Base.h3em;
            padding: 0 .5rem;

        }
    }
}