@use '../../App/Base.module.scss';

.backdrop{
    @include Base.modalBackdrop;
}

.definitions {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 700px;
    width: 100%;
    height: 350px;
    padding: 2rem 1rem;
    background-color: white;
    border-radius: 8px;
    font-family: Base.$font-primary;
    box-shadow: Base.$boxShadow;
    z-index: 1;
}

.closeButton {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

.closeButton:hover {
    transform: scale(1.02);
}

.wordDefContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 40%;
    padding-left: 1rem;
    padding-right: 2rem;
    border-right: Base.$light-200 solid 2px;
}

.wordDefTop {
    display: flex;
    align-items: flex-end;
    width: 90%;
    padding-left: 25px;
}

.pinyin {
    @include Base.pinyin-med;
    padding-right: 4px
}

.modalWord {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
        
    div {
        position: relative;

        .mandarin {
            @include Base.kaiti-med;
        } 
        
        > img:active {
            transform: scale(0.98);
        }
    }  
}

.playButton {
    position: absolute;
    top: 12px;
    left: -40px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.wordDefBottom {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-left: 24px;
    min-height: 84px;

    .translation {
        display: flex;

        > p {
            @include Base.copy-reg;
            line-height: 150%
        }

        > div {
            @include Base.copy-reg;
            min-width: 20px;
            line-height: 150%
        }
    }
}

.wordExamples {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 65%;
    padding-left: 1rem;

    > div {
        padding-bottom: 1rem;
    }
}

@media screen and (max-width: Base.$mobile-screen) {

    .backdrop {
        background-color: rgba(0, 0, 0, 0);
        align-items: flex-end;
    }

    .definitions {
        left: 0;
        bottom: Base.$navBarHeightMobile;
        flex-direction: column;
        align-items: flex-start;
        height: 70%;
        width: 100%;
        padding: 32px 24px;
        box-shadow: none;
        border-radius: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .closeButton {
        top: 30px;
        right: 30px;
    }

    .wordDefContainer {
        width: 100%;
        padding: 0;
        border: none;
    }
    
    .wordDefTop {
        width: 100%;
        padding: 0;
    }

    .pinyin {
        @include Base.pinyin-big;
    }

    .playButton {
        position: absolute;
        top: 8px;
        left: -54px;
        width: 32px;
        height: 32px;   
        cursor: pointer;
    }

    .wordDefBottom {
        width: 100%;
        padding: 0;
    }

    .wordExamples {
        width: 100%;
        padding: 0;
    }
}

