@use '../../App/Base.module.scss';

.topBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 0;
    //border-bottom: 1px solid #f2f2f2;
    z-index: 1;
}

.top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 0.5rem;
    background-color: Base.$light;
    
    > div {
        display: flex;
        align-items: center;
        width: 100%;
    }
}

.lessonTitle {
    @include Base.copy-med;
    color: Base.$highlight-1;
}

.lessonTitleWht {
    @include Base.copy-med;
    color: Base.$light;
}

.returnButton {
    padding: 0.5rem;
}

.returnButtonWht {
    filter: Base.$white-svg; 
}

.moreButton {
    width: 24px;
}

.moreButtonWht {
    width: 24px;
    filter: Base.$white-svg;
}

.bottom {
    width: 100%;
    height: 24px;
    background-color: Base.$light;
    //border-bottom: 1px solid #f2f2f2;
}

.bottomBg {
    height: inherit;
    width: inherit;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: Base.$bg-light;
    //border-bottom: 1px solid #f2f2f2;
}

//Background colors for topbar
.orange {
    background-color: Base.$highlight-1;
}

.white {
    background-color: Base.$light;
}

@media screen and (min-width: Base.$mobile-screen) { 

    .topBar {
        display: none;
    }
}
