
@use '../../App/Base.module.scss';

.profilePic {
    width: 40px;
    border-radius: 100%;
    cursor: pointer;
}

.menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 68px;
    right: 16px;
    width: 235px;
    height: max-content;
    padding: 24px;
    font-family: Base.$font-primary;
    background-color: Base.$body-background;
    border-radius: 8px;
    box-shadow: Base.$boxShadow;
    z-index: 1;
}

.userInfo {
    display: flex;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: Base.$light-200 solid 2px;


    > img {
        width: 64px;
        height: 64px;
        margin-right: 20px;
        border-radius: 100%;
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.optionsList {

    > a {
        cursor: pointer;
    }
    
    > p {
        cursor: pointer;
        padding-top: 24px;
    }
}


@media screen and (max-width: Base.$mobile-screen) {
    
    .menu {
        top: 88px;
        right: 0px;
    }
}