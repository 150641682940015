
@use '../../PageGrid.module.scss';
@use '../../../App/Base.module.scss';

.wordList {
    grid-column: 1 / 5;
    display: grid;
    grid-template-rows: 120px auto;
    height: 100%;
    max-width: 830px; 
    font-family: Base.$font-primary;
    overflow-y: hidden;
}

.filterList {
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
}

.filterOptions {
    position: relative;
    display: flex;
    height: 36px;
    width: min-content;
    border-radius: 8px;
    background-color: white;
}

.noWordMessage {
    display: flex;
    justify-content: space-around;
    padding-top: 6rem;
    text-align: center;

    > div {
        max-width: 260px;

        > h4 {
            @include Base.h4em;
            padding-bottom: 1rem;
        }
    
        > p {
            text-align: center;
        }
    }
    
}

.colorScheme {
    background-color: Base.$bg-light;
}

@media screen and (max-width: Base.$mobile-screen) {

    .wordList {
        grid-template-rows: 100px auto;

        > ul {
            height: 100%;
        }
    }

    .searchInput {
        width: 100%;
    }

    .filterList {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    .filterContainer {
        display: none;
    }
}

@media screen and (min-width: Base.$mobile-screen) {

    .filterOptionsMobile {
        display: none;
    }
}



