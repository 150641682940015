@use '../../PageGrid.module.scss';
@use '../../../App/Base.module.scss';

.pageRef {
    position: absolute;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    width: 0px;
    height: 0px;
}

.flashcardContainer {
    grid-column: 1 / 5;
    grid-row: 1 / 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Base.$font-primary;
}

.flashcardContent { 
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: inherit;
    height: 100%;
    max-height: 550px;
    font-family: Base.$font-primary;
    background-color: white;
    border-radius: 10px;
    padding: 1rem;

    > p {
        margin-top: 20px;
        @include Base.h4em-med;
    }
}

.settings {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;

    .wordCounter {
        @include Base.h4em-med;
        color: Base.$dark-700;
    }

    > img {
        height: 24px;
        width: 24px;
        margin-right: 10px;
        cursor: pointer;
    }

    > img:hover {
        @include Base.onHover
    }
}

.progressBar {
    padding: 1rem 0;
}
    
.wordContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 30%;

    > img {
        height: 3rem;
        cursor: pointer;
        filter: invert(91%) sepia(1%) saturate(0%) hue-rotate(72deg) brightness(90%) contrast(98%);
    }

    > img:active {
        transform: scale(.98);
    }

    > div {
        padding-right: 4rem;
        padding-left: 1rem;
    }
}

.wordBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70%;
}

.definition {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 70%;
    min-width: 10%;
    padding-bottom: 1rem;
    overflow: scroll;

    .translation {
        display: flex;

        > p {
            @include Base.copy-reg;
        }

        > div {
            @include Base.copy-reg;
            min-width: 20px;
        }
    }
}

.exampleContainer {
    overflow: auto;

    > div {
        padding-bottom: 1rem;
    }
}

.buttons {
    width: 100%;
}

.checkButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.controlButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > button {
        width: 49%;
    }
}

.wordOptions {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0;

    > img {
        margin: 5px 10px;
        cursor: pointer;
    }

    > img:hover {
        @include Base.onHover
    }
}

@media screen and (max-width: Base.$mobile-screen) {

    .progressBar {
        padding: 1rem 0 0.5rem;
    }

    .settings {
        justify-content: center;
        padding-bottom: 0.5rem;

        > img {
            display: none;
        }
    }

    .flashcardContent {
        max-height: none;
    }

    .flashcardContainer {
        grid-row: 2 / 3;        
    }

    .wordContainer {
        height: 20%;

        > div {
            padding: 0;
            padding-bottom: 0.5rem;
        }

        > img {
            display: none;
        }
    }

    .definition {
        max-width: 90%;
    }

    .wordBottom {
        height: 80%;
    }

    .wordOptions {
        display: none;
    }

    .playButtonMobile {
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;

        > img {
            height: 3rem;
            cursor: pointer;
        }
    }
}

@media screen and (min-width: Base.$mobile-screen) {

    .flashcardContainer {
        grid-column: 1 / 13;
    }

    .playButtonMobile {
        display: none;
    }
}

@media screen and (min-width: Base.$m-screen) {

    .flashcardContainer {
        width: auto;
    }
}

@media screen and (min-width: Base.$l-screen) {

    .flashcardContainer {
        grid-column: 2 / 12;
        width: 776px;
        justify-self: center;
    }
}

@media screen and (min-width: Base.$xl-screen) {
    
    .flashcardContainer {
        grid-column: 3 / 11;

    }
}
