@use '../../../App/Base.module.scss';

.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0 0;

}

.prefOption {
    display: flex;
    width: 320px;
    padding: 15px 2rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    background-color: Base.$light;
    cursor: pointer;

    > h4 {
        @include Base.h4-bold;
        display: flex;
        align-items: center;
        color: Base.$light-500;
        height: 30px;
        width: 45%;
        margin-right: 1rem;
        border-right: 1px solid Base.$light-200;
    }

    > p {
        @include Base.h4em-med;
    }

    > input {
        appearance: none; /* Remove default appearance */
        -webkit-appearance: none; /* For WebKit browsers */
        -moz-appearance: none;
        color: red;
    }
}
.activeSelect {
    border: 2px solid Base.$highlight-1;
}

.inactiveSelect {
    border: 2px solid Base.$light;
}

