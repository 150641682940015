@use '../../App/Base.module.scss';

.tags {
    display: flex;
    padding-bottom: 0.5rem;

    > p {
        @include Base.copy-sml;
        font-size: 12px;
        display: flex;
        padding: 0px 4px 1px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-right: 4px;
        border-radius: 4px;
        border: 1px solid Base.$light-500;
    }
}