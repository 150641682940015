@use '../../App/Base.module.scss';

.phraseLine {

}

.phraseMandarin {
    @include Base.kaiti-sml;
}

.phrasePinyin {
    @include Base.copy-med;
}

.phraseTrans {
    @include Base.copy-reg;
    font-family: Base.$font-primary;
}
