@use '../../App/Base.module.scss';

.gridHeader {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    height: 100%;
    background-color: Base.$body-background;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    margin: 0 16px 0 32px;
}

.navlistButton {
    display: none;
    cursor: pointer;
}

@media screen and (max-width: Base.$mobile-screen) {

    .gridHeader {
        display: none;
    }
}

@media screen and (max-width: Base.$l-screen) {

    .gridHeader {
        grid-column: 2 / 3;
    }

    .navlistButton {
        display: initial;
    }

    .header {
        margin: 0 16px;
    }
}