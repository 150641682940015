@use '../../../App/Base.module.scss';

.tags {

    > p { 
        @include Base.copy-med;
    }

    > div {
        display: flex;
        flex-wrap: wrap;

        > div { 
            margin-right: 0.5rem;
        }

        > img {
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
            cursor: pointer;
        }
    }
}