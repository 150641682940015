
@use '../../../App/Base.module.scss';

.filter{
    min-width: 180px;
    max-width: 210px;
    padding: 24px;
    border-radius: 8px;
    background-color: white;

    box-shadow: Base.$boxShadow;
    
    .filterHeader {
        @include Base.h4em;
    }
    
    .filterSelect {
        display: flex;
        font-weight: 500;
        padding-top: 10px;
        cursor: pointer;

        > img {
            padding-right: 10px;
        }
    }

    .filterSelectSub {
        display: flex;
        flex-direction: row;
        font-weight: 500;
        padding: 10px 0 0 30px;
        cursor: pointer;

        > img {
            padding-right: 10px;
        }
    }
}