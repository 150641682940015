@use './LessonGrid.module.scss';
@use '../../../App/Base.module.scss';

.audioControlContainer {
    width: 100%;
    max-width: 950px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    font-family: Base.$font-primary;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    button {
        cursor: pointer;
        border: none;
        background-color: rgba(255, 255, 255, 0);
    }

    button:active {
        transform: scale(.989);
        transition: transform 25ms;
    }
    
    .progressBar {
        width: 100%;
        margin-bottom: -10px;
    }
}

.audioControls {
    position: relative;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
        display: flex;
        width: 30%;
        height: 100%;
    }
}

.controls {
    justify-content: center;
}

.playBtn {
    max-width: 46px;
    min-width: 46px;
    margin: 10px;
    padding-left: 5px;
}

.btn {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    margin: auto 20px;

    p {
        font-size: 16px;
    }
}

.settings {
    display: flex;
    justify-content: flex-end;
}

.transcriptActive {
    filter: invert(57%) sepia(72%) saturate(491%) hue-rotate(348deg) brightness(93%) contrast(96%);
}

.speedControls {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 1rem;
    
    > button {
        cursor: pointer;

        
        > h3 {
            @include Base.h5-reg;
        }

        > p {
            @include Base.copy-sml;
        }
    }
}


@media screen and (max-width: Base.$mobile-screen) {

    .audioControlContainer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 90px;
    }

    .audioControls {
        background-color: Base.$mobile-nav;
    }

    .playBtn {
        margin: 0;
    }

    .speedControls{
        display: none;
    }

    .preferences {
        display: none;
    }
}
