
@use '../../../App/Base.module.scss';

.backdrop {
    @include Base.modalBackdrop;
}

.lessonModal {
    position: relative;
    width: 600px;
    padding: 24px;
    border-radius: 8px;
    background-color: white;
    font-family: Base.$font-primary;
    z-index: 1;
}

.lessonContent {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    > img {
        width: 250px;
        height: 250px;
        border-radius: 8px;
    }

    .testScore {
        @include Base.copy-reg;
        position: absolute;
        left: 0;
        top: 0;
        padding: 8px 16px;
        color: white;
        background-color: #13c3c3e6;
        border-top-left-radius: 8px;
    }
}

.lessonDescription {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 250px;
    width: 274px;

    > div {
        
        > h4 {
            @include Base.h4em;
            padding-right: 25px;
        }

        .lessonTime {
            @include Base.copy-med;
            padding: 8px 0;
            color: Base.$dark-700;
        }

        .description {
            @include Base.copy-med;
        }
    }

    .bottomDescription {
        display: flex;
        justify-content: space-between;
        height: 32px;
        
        .tag {
            align-items: center;
            max-height: 27px;
            max-width: 40%;
            font-size: 16px;
            padding: 4px 8px ;
            margin-top: 5px;
            border-radius: 4px;
            line-height: 120%;
            color: white;
            word-break: normal;
            background-color: Base.$highlight-1;  
        }

        .progressBar {
            display: flex;
            flex-direction: column-reverse;
            width: 200px;

            > p {
                color: Base.$dark-700;
                text-align: end;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                padding-bottom: 4px;
            }
        }
    }
}

.closeButton {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 20px;
    top: 25px;
    cursor: pointer;
    z-index: 1;
}

.modalButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    > a {
        margin-top: 1.5rem;
    }
}