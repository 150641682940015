@use '../../../App/Base.module.scss';


.fileUpload {
    display: flex; 
    padding-bottom: 1rem;

    > div {
        display: flex;
        flex-direction: column;
    }
}

.file {
    > input, textarea {
        @include Base.copy-med;
        border: 1.5px solid Base.$light-200;
        border-radius: 4px;
        padding: 8px;
        resize: none;
        margin-bottom: 1rem;
    }
    
    > input::placeholder, textarea::placeholder {
        color: Base.$light-500;
    }
}

.audioFileInput {
    height: 128px;
    width: 376px;
    background-color: Base.$light;
    border: 2px dotted Base.$light-200;
    border-radius: 4px;
    margin-right: 1.5rem;
    padding: 1rem;
}

.audioFileMessage {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;

    > img {
        padding-bottom: 1rem;
    }

    > p {
        @include Base.copy-med;
        color: Base.$light-500;
    }
}

.imageFileMessage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    > img {
        padding-top: 1rem;
    }

    > p {
        @include Base.copy-med;
        color: Base.$light-500;
        padding-bottom: 1rem;
    }
}

.imagePreview {
    height: 100%;
    width: 100%;
}

.imageFileInput {
    position: relative;;
    height: 128px;
    width: 128px;
    background-color: Base.$light;
    border: 2px dotted Base.$light-200;
    border-radius: 4px;
    margin-right: 1.5rem;
}

.clearImage {
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 4px;
    z-index: 1;
    cursor: pointer;
}


.label {
    @include Base.copy-med;
    padding-bottom: 0.5rem;
}