
@use "../../../App/Base.module.scss";

.pageGrid {
    justify-items: center;
}

.resourcePageBody {
    grid-column: 1 / 5;
    grid-row: 2 / 3;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.headerBreadCrumb {
    @include Base.h4-bold;
    color: Base.$light;
}

.resourceHeader {
    display: flex;
    justify-content: space-between;
    width: inherit;
    padding: 0 0 1rem;
    background-color: Base.$bg-light;

    > button {
        margin-left: 2rem;
    }
}

.grammarList {
    height: calc(100% - 50px);
    overflow: auto;
    padding-bottom: 2rem;
}


@media screen and (min-width: Base.$mobile-screen) {
    .resourcePageBody {
        grid-column: 1 / 13;
        grid-row: 1 / 3;
        max-width: 632px;
    }

    .resourceHeader { 
        padding: 2rem 0 1rem;
    }
}


@media screen and (min-width: Base.$l-screen) {
    .resourcePageBody {
        grid-column: 1 / 13;
    }
}


@media screen and (min-width: Base.$xl-screen) {
    .resourcePageBody {
        max-width: 826px;
    }
}


