@use '../../App/Base.module.scss';

.backdrop{
    @include Base.modalBackdrop;
}

.modalBody {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 1rem 1rem;
    max-width: 360px;
    height: 300px;
    width: 90%;
    position: relative;
    z-index: 1;

    .closebutton {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }

    > h3 {
        @include Base.h3em;
    }

    > p {
        @include Base.copy-reg;
        display: flex;
        text-align: center;
        align-items: center;
        height: 40px;

        > img {
            display: inline;
            width: 1.5rem;
            height: 1.5rem;
            margin: 0 4px 6px;
        }
    }
}
