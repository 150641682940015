@use '../../../App/Base.module.scss';

.backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.mobileModal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 1rem 1.5rem 0;
    background-color: white;
    z-index: 1;
}

.closeButton {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 20px;
    top: 16px;
    cursor: pointer;
    z-index: 1;
}

.lessonContent {
    position: relative;
    width: inherit;
}

.testScore {
    @include Base.copy-reg;
    position: absolute;
    left: 0;
    padding: 8px 16px;
    color: white;
    background-color: #13c3c3e6;
    border-top-left-radius: 8px;
}

.lessonHeader {
    @include Base.h4em;
    padding-bottom: 1.5rem;
}

.lessonImage {
    height: 210px;
    width: inherit;
    object-fit: cover;
    margin-bottom: 1.5rem;
    border-radius: 8px;
}

.lessonTime {
    @include Base.copy-med;
    padding-bottom: 8px;
    color: Base.$dark-700;
}

.description {
    @include Base.copy-med;
    padding-bottom: 8px;
}

.progressBar {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding-top: 12px;

    > p {
        font-family: Base.$font-primary;
        color: Base.$dark-700;
        text-align: end;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        padding-bottom: 4px;
    }
}

.modalButtons {
    width: 100%;
    padding: 0.5rem 1rem 100px;
    position: absolute;
    bottom: 0;
    align-self: center;
}

@media screen and (max-height: Base.$mobile-s) {

    .lessonHeader {
        padding-bottom: 10px;
    }

    .lessonImage {
        height: 190px;
        margin-bottom: 12px;
    }

    .description{
        padding: 0;
    }

    .progressBar {
        padding-top: 6px;
    }

    .modalButtons {
        padding-bottom: 80px;
    }
}

