
@use '../../../App/Base.module.scss';

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 380px;
    min-width: 270px;
    max-width: 270px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    overflow: clip;
    font-family: Base.$font-primary;
    background-color: white;
    cursor: pointer;
    
    margin: 0.5rem;
}

.card:hover {
    transform: scale(1.01);
    transition: transform 1ms;
}

.top {
    display: flex;
    align-self: center;
    position: relative;
    width: 100%;
    
    > img{
        height: 170px;
        width: 100%;
        object-fit: cover;
        border-bottom: 1px solid darkgray;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    // user test percentage
    > aside {
        @include Base.copy-reg;
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px 16px;
        border-top-left-radius: 8px;
        color: white;
        background-color: #13c3c3e6;
    }

    // part 1 / 5
    > div {
        position: absolute;
        bottom: 0;
        right: 8px;
        bottom: 8px;
        padding: 4px 8px;
        font-size: 16px;
        border-radius: 4px;
        color: white;
        background-color: rgba(0, 0, 0, .5);
    }
}

.cardDescription {
    padding: 8px 16px 0 ;

    > h3 { 
        font-size: 18px;
        font-family: Base.$font-primary;
    }
}

.lessonTime {
    @include Base.copy-med;
    color: Base.$light-200;
    padding: 8px 0;
}

.description {
    width: 100%;
    word-break: keep-all;
    white-space: normal;
}

.bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 16px 16px;
    
    .progressBar {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 16px;
        width: 70%;
    
    }
    
    .matchingPercent {
        color: Base.$dark-700;
        text-align: end;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        padding-bottom: 4px;
    }
}

// Used in all cards
.tag {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 27px;
    max-width: 40%;
    font-size: 16px;
    padding: 4px 8px ;
    border-radius: 4px;
    line-height: 120%;
    color: white;
    word-break: normal;
    background-color: Base.$highlight-1;  
}


// large card style

.cardLarge {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 420px;
    width: 376px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    overflow: clip;
    font-family: Base.$font-primary;
    background-color: white;
}

.topLarge {
    display: flex;
    align-self: center;
    position: relative;
    width: 100%;
    
    > img{
        height: 230px;
        width: 100%;
        object-fit: cover;
        border-bottom: 1px solid darkgray;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    // user test percentage
    > aside {
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px 16px;
        font-size: 16px;
        border-top-left-radius: 8px;
        color: white;
        background-color: rgba(19, 195, 195, 0.9);
    }

    // part 1 / 5
    > div {
        position: absolute;
        bottom: 0;
        right: 8px;
        bottom: 8px;
        padding: 4px 8px;
        font-size: 16px;
        border-radius: 4px;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

/* featured lesson */
.featuredCard {
    display: flex;
    height: 200px;
    width: 100%;
    margin: 0.5rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 8px;
    font-family: Base.$font-primary;
    

    .featuredLeft{
        position: relative;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        overflow: hidden;

        > img {
            width: 100%;
            object-fit: cover;
        }  
        
        > aside {
            position: absolute;
            top: 0;
            left: 0;
            padding: 6px 12px;
            font-size: 16px;
            border-top-left-radius: 8px;
            color: white;
            background-color: #13c3c3e6;
        }
    }

    .featuredRight {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .featuredTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
    }
    
    .featuredLength {
        color: Base.$light-200;
    }

    .featuredDescription {
        @include Base.copy-med;
    }

    .rightBottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        
        .progressBar {
            display: flex;
            flex-direction: column-reverse;
            width: 65%;
        
            > p {
                text-align: end;
                padding-bottom: 4px;
            }
        }
    }

}

.featuredCard:hover {
    transform: scale(1.01);
    transition: transform 1ms;
}

//mobile size
@media screen and (max-width: Base.$mobile-screen) {

    .featuredCard {
        height: 100px;
        background-color: rgba(255, 255, 255, 0);
        box-shadow: none;

        margin: 0 0 1rem;
    }

    .featuredLeft {
        width: 40%;
        padding-right: 1rem;

        > img {
            height: auto;
        }

        > aside {
            display: none;
        }
    }

    .featuredRight {
        width: 60%;
        padding: 0;
    }

    .featuredLength {
        padding: 0;
    }

    .featuredDescription {
        display: none;
    }
    
    .featuredTitle {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
        }
}

//desktop
@media screen and (min-width: Base.$mobile-screen) {

    .featuredLeft {
        width: 45%;
        padding-right: 12px;

        > img {
            width: 100%;
            height: 100%;    
        }
    }

    .featuredLength {
        padding: 4px 0 8px;
    }

    .featuredRight {
        width: 55%;
        padding: 16px 24px 16px 12px;
    }
}



