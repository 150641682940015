@use '../../../App/Base.module.scss';

.searchBar {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    > a {
        margin-left: 1rem;
    }
}