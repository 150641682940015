@use '../../PageGrid.module.scss';
@use './LessonGrid.module.scss';
@use '../../../App/Base.module.scss';

//$visual-center: 100px; //create an offset for visual evenness

.instructionMessage {
    @include Base.h3em;
    text-align: center;
    padding: 0;
}

.pageRef {
    border: none;
    //outline: none;
    background-color: rgba(255, 255, 255, 0);
    width: 0px;
    height: 0px;
}

.lessonContent {
    overflow-y: scroll;
}

.phraseContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 5%;
    border-radius: 10px;
    overflow-y: scroll;
}

.phraseContainerBothTypes {
    > div {
        align-items: center;
    }
}

.showWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    cursor: pointer;
}

//**************grid items*********************//

.bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%; 
}

.translation {
    display: flex; 
    justify-content: center;
    align-items: center;
    min-height: 120px;
    height: 100%;
    padding: 0 5%;
    border-top: 2px solid Base.$light-200;
    
    > h4 {
        position: relative;
        @include Base.h4em-med;
        padding: 0 5%;
    }
}


.transVisible, .transHidden {
    font-size: 24px;   
}

.transHidden {
    filter: blur(10px);
}

.testbuttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 15%;

    > button {
        width: 48%;
        margin-bottom: 1rem;
    }
}



@media screen and (max-width: Base.$mobile-screen) {

    .testbuttons {
        padding: 0;
    }
    
    .translation {
        min-height: 150px;
        padding: 0;

        > h4 {
            padding: 0;
            
            > img {
                display: none;
            }
        }       
    }

    .phraseContainer {
        justify-content: space-between;
        padding: 0 5% 0 0;
    }
}

@media screen and (min-width: Base.$mobile-screen) {

    .instructionMessage {
        padding: 2rem .5rem 0;
    }

    .phraseContainer {

        > div {
            height: 100%;
        }
    }

    .fillerBlock {
        display: none;
    }

}

@media screen and (min-width: Base.$l-screen) {
    
    .instructionMessage {
        @include Base.h2em;
        
    }
}


