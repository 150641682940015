
.selector {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    //border: 1px dotted red;

    position:absolute;

    //width: 28px;
    //height: 28px;

    > img {
        /* width: 14px;
        height: 10px; */
        cursor: pointer;
    }
    
    > img:active {
        transform: scale(.97);
    }
}
