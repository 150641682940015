@use '../../App/Base.module.scss';

* {
    outline: none;
}

.gridContainer {
    display: grid;
    grid-template-columns: Base.$navBarWidth auto;
    grid-template-rows: Base.$headerHeight auto;
    height: 100%;
    background: Base.$body-background;
}

.gridPageContent {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    background-color: Base.$body-background;
    
    /* App grid body */
    display: grid;
    overflow-y: hidden;
}

@media screen and (max-width: Base.$l-screen) {
    
    .gridContainer {
        grid-template-columns: 0;
    }
}

@media screen and (max-width: Base.$mobile-screen) {
    .gridContainer {
        grid-template-columns: auto;
        grid-template-rows: 0;
    }

    .gridHeader {
        display: none;
    }

    .gridPageContent {
        /* App gridBody */
        grid-column: 1 / 2;
    } 
}



