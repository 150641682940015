@use '../../App/Base.module.scss';

.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 200px;
}

.vertical{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
}

.navButtons {
    display: flex;
    justify-content: space-around;
    width: 90%;
}

.navButtonsSideways {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.navLine {
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 90%;
    padding: 1rem 0;
}

.navLineSideways {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 1rem;
}

.line {
    position: absolute;
    border-top: 2px solid Base.$highlight-6;
    height: 0.1rem;
    align-self: center;
}

.lineSideways {
    position: absolute;
    width: 0.1rem;
    border-right: 2px solid Base.$highlight-6;
    align-self: center;
}

.lineGrey {
    border-color: Base.$light-200;
}

@media screen and (max-width: Base.$mobile-screen) {

    .navButtons {
        width: 100%;
    }

    .navLine {
        width: 100%;
    }

    .vertical {
        padding: 0;
    }
    
    .mobileCentered {
        padding: 0 1.5rem;
    }

} 


