@use "../../App/Base.module.scss";

.wordModal {
    max-width: 390px;
    width: 100%;
    min-height: 150px;
    background-color: Base.$light;
    padding: 1rem 1.5rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.25);
}

.wordDef {

}

.pinyin {
    @include Base.copy-med;
}

.wordDefTop {
    display: flex;
    justify-content: space-between;
}

.translation {
    @include Base.copy-med;
    padding: 0.25rem 0 1rem
}

.wordExamples {
    display: flex;
    justify-content: center
}

.exampleTag {
    @include Base.copy-med;
    color: Base.$light;
    font-size: 12px;
    padding: 2px 4px;
    margin-bottom: 0.5rem;
    width: 120px;
    border-radius: 4px;
    background-color: Base.$light-500;
}

.exampleSentance {
    padding-bottom: 0.5rem;
    
    > p {
        font-size: 16px;
        
    }
}

.playAudio {
    background-color: Base.$bg-light; 
    padding: 3px 3px;
    margin: 12px 0;
    border-radius: 4px;
    cursor: pointer;
}

.wordOptions {

}