
@use '../../App/Base.module.scss';


.gridNav {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    background-color: Base.$highlight-1;
    max-height: 100vh;
}

.gridNavMobile {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: Base.$navBarHeightMobile;
    background-color: Base.$highlight-1;
    z-index: 1;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: 80px;

    > img {
        width: 55px;
    }
}

.icon {
    max-width: 32px;
    min-width: 32px;
    margin: 8px;
} 

.navLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    height: calc(100% - #{Base.$header-offset});
    gap: 3rem;
    padding-bottom: 3rem;

    > a {
        > span {
            @include Base.nav-btn-large;
        }
    }
}

.notActive {
        display: flex;
        text-decoration: none;
    
        .icon {
            //white color for svg
            filter: Base.$white-svg;
        }

        > span {
            color: Base.$light;
        }
    }

.notActive:hover {
    background-color: Base.$light;
    border-radius: 10px;
        
    .icon {
        // orange color for svg
        filter: Base.$hl-1svg;
    } 

    > span {
        color: Base.$highlight-1;
    }
    
}

.active {
    background-color: Base.$light;
    border-radius: 10px;
    
    .icon {
        //orange color svg
        filter: Base.$hl-1svg;
    }

    > span {
        color: Base.$highlight-1;
    }

}

@media screen and (max-width: Base.$mobile-screen) {
    
    .gridNav {
        display: none;
    }

    .gridNavMobile {
        display: block;
        grid-column: 1 / 5;
        grid-row: 3 / 4;
    }
    
    .logo {
        display: none;
    } 

    .navLinks {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        padding: 0;
        gap: 0;

        > a {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 70px;
            max-height: 70px;
            min-height: 70px;
            
            > span {
                display: block;
                @include Base.nav-btn;
            }
        }
    }

    .active {
        background-color: Base.$light;
    }

    .notActive {
        .icon {
            filter: Base.$white-svg;
        }
    }

    .notActive:hover {
        background-color: rgba(255, 255, 255, 0);
    }
}

@media screen and (max-width: Base.$l-screen) {

    .gridNav {
        position: absolute;
        top: Base.$headerHeight;
        bottom: 0;
        width: 240px;
        z-index: 1;
    }

    .navLinks {

        > a {
            display: flex;
            width: 160px;

            > span {
                align-self: center;
            }
        }
    }
}

@media screen and (min-width: Base.$l-screen) {
    .navLinks {
        
        > a {
            > span {
                display: none;
            }
        }
    }
}
