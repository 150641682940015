
@use '../../../App/Base.module.scss';

.backdrop {
    @include Base.modalBackdrop;
    z-index: 1;
}

.prefContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding: 1rem 2rem;
    background-color: white;
    border-radius: 8px;
    z-index: 1;
    font-family: Base.$font-primary;
    box-shadow: Base.$boxShadow;
}

.closeButton {
    position: absolute;
    padding: 8px;
    right: 8px;
    top: 8px;
    cursor: pointer;
}

.prefHeader {
    @include Base.h3em;
}

.prefBody {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: Base.$bg-light;
    border-radius: 8px;
    padding: 1rem;
}

.prefBodyTop {
    width: 100%;
    min-height: 94px;
};

.dropdownMenus {
    display: flex;
    width: 100%;
    padding: 0 0 1rem;
    justify-content: space-between;
}

.wordList {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > p {
        @include Base.copy-bold;
    }
}

.prefMessage {
    @include Base.copy-reg;
}

.prefTypeToggle {
    display: flex;
    background-color: Base.$bg-light;
    border-radius: 8px;
    margin: 1rem 0;
    
    > p {
        @include Base.copy-med;
        padding: 6px 2rem;
        border-radius: 8px;
        cursor: pointer;
    }
}

.activeSelect {
    color: Base.$light;
    background-color: Base.$highlight-1;
}


@media screen and (max-width: Base.$mobile-screen) {

    .backdrop {
        align-items: flex-end;
        padding-bottom: Base.$navBarHeightMobile;
    }

    .prefContainer {
        padding: 2rem 1rem 1rem;
    }

    .prefBody {
        padding: 1rem;
    }

    .prefHeader {
        font-size: 24px;
    }
}