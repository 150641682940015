@use '../../../App/Base.module.scss';


.wordDiv {
    display:flex;
    padding: 8px;
    height: 94px;
    min-width: 128px;
    background-color: Base.$bg-light;;
    border-radius: 8px;
    margin-right: 1rem;

    > input {
        width: 50px;
        margin: 0 20px;
    }

    .buttonDiv {
        display:flex;
        flex-direction: row;

        > button {
            padding: 0px 5px;
        }
    }
}

.inputBox {
    @include Base.copy-med;
    border: 1.5px solid Base.$light-200;
    border-radius: 4px;
    padding: 4px 8px;
    resize: none;
    height: 32px;
    width: 72px;
}

.inputBox:focus {
    border: 2px solid Base.$highlight-1; /* Add a blue border when the input is in focus */
  }

.simplifiedInput {
    display: flex;
    flex-direction: column;

    > input {
        margin: 4px 0 8px;
    }
}

.timeEditor {
   position: relative;

    > input {
        padding: 4px 24px 4px 8px;
    }
}

.moreControls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 0.25rem;

    > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    
    > img:active {
        transform: scale(.97);
    }
}

.trashButton {
    margin-top: 9px;


}

.playButton {
    margin-top: 19px;
}



