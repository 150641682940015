@use '../../../App/Base.module.scss';


.wordList {
    position: relative;
    max-height: 90%;
    height: 100%;
    max-width: 880px;
    width: 100%;
    background-color: Base.$light;
    border-radius: 8px;
    padding: 2rem 1rem 1rem;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.list {
    height: 90%;
    overflow: auto;
}

.duallist {
    display: flex;
    justify-content: space-evenly;

    > ul {
        min-width: 400px;
        margin: 0 0.5rem;
    }
}

.closeButton {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 8px;
    cursor: pointer;
}

@media screen and (max-width: Base.$mobile-screen) {

    /* .list {
        height: 100%;
        padding-bottom: 70px;
    } */

    .wordList {
        max-height: 100%;
        padding: 0.5rem 0.5rem 0;
    }

    .modalHeader {
        padding: 2rem 0 1rem;
    }

}